import React, { useContext, useState } from 'react'
// // Firebase
import firebase from 'auth/Auth'
// Context
import UserContext from 'context/UserContext'
// react library for routing
import { Link } from 'react-router-dom'
// reactstrap components
import {
  NavbarBrand,
  Navbar,
  Nav,
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  NavbarToggler,
  Collapse,
  DropdownMenu
} from 'reactstrap'

const ConfigNavbar = props => {
  const context = useContext(UserContext)
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <>
      <Navbar className='navbar-horizontal navbar-main navbar-dark navbar-transparent' color='primary' light expand='md'>
        <Container>
          <NavbarBrand to='/' tag={Link}>
            <img
              alt='...'
              src={require('assets/img/brand/logo-white.svg')}
            />
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className='ml-auto' navbar>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  <i className={'fas fa-user mr-2 ' + (context.guestUser ? ' text-gray' : 'text-white')} />
                  {context.guestUser ? (
                    <>
                      <span className='nav-link-inner--text'><span className='font-weight-bolder text-xs text-gray mr-2'>INVITADO </span>{context.guestUser.email} </span>
                    </>
                  ) : (
                    <span className='nav-link-inner--text'>{context.user.email}</span>
                  )}

                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    onClick={() => firebase.auth().signOut()}
                  >
                    <i className='ni ni-button-power' />
                    <span>Cerrar sesión</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default ConfigNavbar
