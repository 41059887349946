const STRIPE_PUBLISHABLE_KEY = 'pk_live_51HXTYPHxEtHzRcUzgTdodPsKKP0JmINeFimVavjNfNSC9VbwVGVq5IhTD1U4dDwgi22GPPmTaU4old6ITccltOXM00LS72Ojr4'

const taxRates = ['txr_1HopgbHxEtHzRcUzTab8gc8o']

const PLANS = {
  basic: {
    materials: 25,
    stats: ['basic'],
    appPosition: 'basic',
    pushMonth: 0,
    id: 'prod_IFXGohHVyFMchm',
    prices: {
      monthly: {
        price: '40,99',
        id: 'price_1HopJmHxEtHzRcUzj4G7ieDe'
      },
      yearly: {
        price: '34,99',
        id: 'price_1HopJMHxEtHzRcUznPXF35Fl'
      }
    }
  },
  pro: {
    materials: 50,
    stats: ['basic', 'pro'],
    appPosition: 'pro',
    pushMonth: 0,
    id: 'prod_IN8gjM3sl3EK56',
    prices: {
      monthly: {
        price: '89,99',
        id: 'price_1HopNzHxEtHzRcUzfGz4lzM3'
      },
      yearly: {
        price: '74,99',
        id: 'price_1HopOCHxEtHzRcUzy7feLsMo'
      }
    }
  },
  premium: {
    materials: 100,
    stats: ['basic', 'pro', 'premium'],
    appPosition: 'premium',
    pushMonth: 2,
    id: 'prod_IN8g9rOBF0NgfZ',
    prices: {
      monthly: {
        price: '135,99',
        id: 'price_1HopUBHxEtHzRcUzoXFoS7Tl'
      },
      yearly: {
        price: '114,99',
        id: 'price_1HopVjHxEtHzRcUzNYtLtbIU'
      }
    }
  }
}

export { STRIPE_PUBLISHABLE_KEY, taxRates, PLANS }
