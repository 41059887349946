import React, { useContext, useState, useEffect } from 'react'
// Context
import UserContext from 'context/UserContext'
// // Firebase
import firebase from 'auth/Auth'
// reactstrap components
import {
  Container,
  Card,
  CardTitle,
  CardBody,
  CardFooter,
  Button,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Alert
} from 'reactstrap'
// Core components
import Loader from 'components/Loader/Loader'
// Formik
import { Formik } from 'formik'
import * as Yup from 'yup'
// Image Upload
import ImageUpload from 'components/ImageUpload/ImageUpload'

const styles = {
  boxLabel: {
    minWidth: 180
  }
}

export default function CustomerInfo () {
  const context = useContext(UserContext)
  const [loading, setLoading] = useState(true)
  const [errorMsg, setErrorMsg] = useState(null)
  const [successMsg, setSuccessMsg] = useState(null)
  const [customerInfoAddress, setCustomerInfoAddress] = useState({})
  const [taxId, setTaxId] = useState(null)

  const openUpdateBillingDetails = async () => {
    const functionRef = firebase
      .functions('europe-west3')
      .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink')
    const { data } = await functionRef({ returnUrl: window.location.origin })
    window.location.href = data.url
  }

  useEffect(() => {
    const getAsyncInfo = async () => {
      const stripeGetCustomer = firebase.functions().httpsCallable('stripeGetCustomer')
      const responseCustomer = await stripeGetCustomer({ customerId: context.user.data.stripeId })
      if (responseCustomer) {
        setCustomerInfoAddress({
          ...responseCustomer.data.address, billingName: responseCustomer.data.name
        })
      }

      const stripeGetTaxId = firebase.functions().httpsCallable('stripeGetTaxId')
      const responseTaxId = await stripeGetTaxId({ customerId: context.user.data.stripeId })
      if (responseTaxId.data.data.length > 0) {
        setTaxId(responseTaxId.data.data[responseTaxId.data.data.length - 1].value)
      }

      setLoading(false)
    }
    getAsyncInfo()
  }, [context.user.data.stripeId])

  return (
    <div>
      {errorMsg && (
        <div style={{
          position: 'absolute',
          top: 20,
          zIndex: '999',
          width: '100%'
        }}
        >
          <Alert className='mt-5 container' color='danger' fade>
            <span className='alert-inner--icon'>
              <i className='fas fa-exclamation-triangle' />
            </span>{' '}
            <span className='alert-inner--text'>
              <strong>Error: </strong> {errorMsg}
            </span>
            <button onClick={() => setErrorMsg(null)} type='button' className='close' aria-label='Close'>
              <span className='text-white' aria-hidden='true'>×</span>
            </button>
          </Alert>
        </div>
      )}
      {successMsg && (
        <div style={{
          position: 'absolute',
          top: 20,
          zIndex: '999',
          width: '100%'
        }}
        >
          <Alert className='mt-5 container' color='success' fade>
            <span className='alert-inner--icon'>
              <i className='fas fa-check' />
            </span>{' '}
            <span className='alert-inner--text'>
              {successMsg}
            </span>
            <button onClick={() => setSuccessMsg(null)} type='button' className='close' aria-label='Close'>
              <span className='text-white' aria-hidden='true'>×</span>
            </button>
          </Alert>
        </div>
      )}
      <Formik
        validateOnMount
        initialValues={{ ...context.company, ...customerInfoAddress, taxId } || {}}
        enableReinitialize
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true)
          const convertToSlug = (name) => {
            return name
              .toLowerCase()
              .replace(/ /g, '-')
              .replace(/[^\w-]+/g, '')
          }

          const companySlug = context.company ? context.company.id : convertToSlug(values.name)

          // Check if company exists
          const ref = await firebase.firestore()
            .collection('companies')
            .doc(companySlug)
            .get()

          // Check if company name exists and if it's creating the profile first time
          if (ref.exists && !context.company) {
            setErrorMsg('Nombre de empresa ya existente. Por favor, selecciona uno diferente.')
          } else {
            // Check if image has been updated
            let urlImage = null
            if (typeof values.logo !== 'string') {
              const uploadLayer = await firebase.storage()
                .ref()
                .child(`companies/${companySlug}/${companySlug}.png`)
                .put(values.logo)

              urlImage = await uploadLayer.ref.getDownloadURL()
            }

            const promises = []

            // Add Company Info
            promises.push(firebase.firestore()
              .collection('companies')
              .doc(companySlug)
              .set({
                name: values.name,
                contactEmail: values.contactEmail,
                website: values.website,
                phone: values.phone,
                ...(urlImage && { logo: urlImage })
              }, { merge: true }))

            // Add company slug to customer
            promises.push(firebase.firestore()
              .collection('customers')
              .doc(context.user.uid)
              .update({
                company: companySlug
              }))

            const stripeUpdateCustomer = firebase.functions().httpsCallable('stripeUpdateCustomer')
            promises.push(stripeUpdateCustomer({
              customerId: context.user.data.stripeId,
              updateObject: {
                name: values.billingName,
                address: {
                  line1: values.line1,
                  line2: values.line2,
                  country: values.country,
                  city: values.city,
                  state: values.state,
                  postal_code: values.postal_code
                }
              }
            }))

            if (values.taxId !== taxId) {
              const stripeCreateTaxId = firebase.functions().httpsCallable('stripeCreateTaxId')
              promises.push(stripeCreateTaxId({
                customerId: context.user.data.stripeId,
                updateObject: {
                  type: 'es_cif', value: values.taxId
                }
              }))
            }

            Promise.all(promises).then(response => {
              console.log(response)
              setSubmitting(false)
              setSuccessMsg('Información actualizada correctamente.')
            }).catch(err => {
              setErrorMsg('Ha ocurrido un error')
              console.log(err)
              setSubmitting(false)
            })
          }
        }}
        validationSchema={Yup.object().shape({
          logo: Yup.string()
            .required(),
          name: Yup.string()
            .required(),
          contactEmail: Yup.string()
            .email()
            .required(),
          phone: Yup.number()
            .min(9)
            .required(),
          website: Yup.string()
            .required(),
          billingName: Yup.string()
            .required(),
          taxId: Yup.string()
            .matches(/[A-Z][0-9]{8}$/g)
            .required(),
          line1: Yup.string()
            .required(),
          line2: Yup.string(),
          country: Yup.string()
            .required(),
          state: Yup.string()
            .required(),
          city: Yup.string()
            .required(),
          postal_code: Yup.string()
            .required()
        })}
      >
        {formikProps => {
          const {
            values,
            isValid,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            errors,
            touched
          } = formikProps
          return (
            <form onSubmit={handleSubmit}>
              <Container className='pt-8'>
                <Card className='p-4'>
                  <CardTitle
                    tag='h5'
                    className='text-uppercase text-muted mb-0'
                  >
                    Perfil de la empresa
                  </CardTitle>
                  <CardBody>
                    {loading ? <Loader /> : (
                      <>
                        <Row className='align-items-center'>
                          <Col lg={{ size: 'auto' }}>
                            <span className='text-primary text-sm'>Datos públicos mostrados en <b>Overlapp</b></span>
                          </Col>
                          <Col>
                            <hr />
                          </Col>
                        </Row>
                        <Row>
                          <Col md='12' lg='3' xl='3'>
                            <ImageUpload
                              preview={
                                typeof values.logo === 'string'
                                  ? values.logo
                                  : context.company?.logo
                              }
                              onChange={(file, image) => {
                                setFieldValue('logo', file)
                              }}
                            />
                            <h5><b>Formato:</b> JPG o PNG</h5>
                            <h5><b>Tamaño:</b> 800x800 píxeles</h5>
                            <h5><b>Resolución:</b> 72 ppp</h5>
                            {errors.logo && (
                              <div className='text-xs text-danger position-absolute'>* El campo imagen es obligatorio</div>
                            )}
                          </Col>
                          <Col md='12' lg='9' xl='9'>
                            <InputGroup>
                              <InputGroupAddon addonType='prepend'>
                                <InputGroupText style={styles.boxLabel}>Nombre</InputGroupText>
                              </InputGroupAddon>
                              <Input
                                name='name'
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder='Nombre de la empresa...'
                                type='text'
                              />
                            </InputGroup>
                            {errors.name && touched.name && (
                              <div className='text-xs text-danger position-absolute'>* Hay un error en el campo Nombre</div>
                            )}
                            <InputGroup className='mt-4'>
                              <InputGroupAddon addonType='prepend'>
                                <InputGroupText style={styles.boxLabel}>Website</InputGroupText>
                              </InputGroupAddon>
                              <Input
                                name='website'
                                value={values.website}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder='https://empresa.com...'
                                type='url'
                              />
                            </InputGroup>
                            {errors.website && touched.website && (
                              <div className='text-xs text-danger position-absolute'>* Hay un error en el campo Website</div>
                            )}
                            <InputGroup className='mt-4'>
                              <InputGroupAddon addonType='prepend'>
                                <InputGroupText style={styles.boxLabel}>Teléfono</InputGroupText>
                              </InputGroupAddon>
                              <Input
                                name='phone'
                                value={values.phone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder='Telefóno de contacto...'
                                type='number'
                              />
                            </InputGroup>
                            {errors.phone && touched.phone && (
                              <div className='text-xs text-danger position-absolute'>* Hay un error en el campo Teléfono</div>
                            )}
                            <InputGroup className='mt-4'>
                              <InputGroupAddon addonType='prepend'>
                                <InputGroupText style={styles.boxLabel}>Email</InputGroupText>
                              </InputGroupAddon>
                              <Input
                                name='contactEmail'
                                value={values.contactEmail}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder='Email de contacto...'
                                type='text'
                              />
                            </InputGroup>
                            {errors.contactEmail && touched.contactEmail && (
                              <div className='text-xs text-danger position-absolute'>* Hay un error en el campo Email</div>
                            )}
                          </Col>
                        </Row>
                        <Row className='align-items-center mt-6'>
                          <Col lg={{ size: 'auto' }}>
                            <span className='text-primary text-sm'>
                              Datos de <b>facturación</b>
                            </span>
                          </Col>
                          <Col>
                            <hr />
                          </Col>
                        </Row>
                        <Row>
                          <Col sm='12'>
                            <InputGroup>
                              <InputGroupAddon addonType='prepend'>
                                <InputGroupText style={styles.boxLabel}>Razón social</InputGroupText>
                              </InputGroupAddon>
                              <Input
                                name='billingName'
                                value={values.billingName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder='Razón social...'
                                type='text'
                              />
                            </InputGroup>
                            {errors.billingName && touched.billingName && (
                              <div className='text-xs text-danger position-absolute'>* Hay un error en el campo Razón Social</div>
                            )}
                            <InputGroup className='mt-4'>
                              <InputGroupAddon addonType='prepend'>
                                <InputGroupText style={styles.boxLabel}>CIF</InputGroupText>
                              </InputGroupAddon>
                              <Input
                                name='taxId'
                                value={values.taxId}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder='CIF...'
                                type='text'
                              />
                            </InputGroup>
                            {errors.taxId && touched.taxId && (
                              <div className='text-xs text-danger position-absolute'>* Hay un error en el campo CIF</div>
                            )}
                            <InputGroup className='mt-4'>
                              <InputGroupAddon addonType='prepend'>
                                <InputGroupText style={styles.boxLabel}>Dirección línea 1</InputGroupText>
                              </InputGroupAddon>
                              <Input
                                name='line1'
                                value={values.line1}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder='Dirección línea 1...'
                                type='text'
                              />
                            </InputGroup>
                            {errors.line1 && touched.line1 && (
                              <div className='text-xs text-danger position-absolute'>* Hay un error en el campo Dirección Línea 1</div>
                            )}
                            <InputGroup className='mt-4'>
                              <InputGroupAddon addonType='prepend'>
                                <InputGroupText style={styles.boxLabel}>Dirección línea 2</InputGroupText>
                              </InputGroupAddon>
                              <Input
                                name='line2'
                                value={values.line2}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder='Dirección línea 2...'
                                type='text'
                              />
                            </InputGroup>
                            {errors.line2 && touched.line2 && (
                              <div className='text-xs text-danger position-absolute'>* Hay un error en el campo Dirección Línea 2</div>
                            )}
                            <InputGroup className='mt-4'>
                              <InputGroupAddon addonType='prepend'>
                                <InputGroupText style={styles.boxLabel}>País</InputGroupText>
                              </InputGroupAddon>
                              <Input
                                name='country'
                                value={values.country}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder='País...'
                                type='text'
                              />
                            </InputGroup>
                            {errors.country && touched.country && (
                              <div className='text-xs text-danger position-absolute'>* Hay un error en el campo País</div>
                            )}
                            <InputGroup className='mt-4'>
                              <InputGroupAddon addonType='prepend'>
                                <InputGroupText style={styles.boxLabel}>Provincia</InputGroupText>
                              </InputGroupAddon>
                              <Input
                                name='state'
                                value={values.state}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder='Provincia...'
                                type='text'
                              />
                            </InputGroup>
                            {errors.state && touched.state && (
                              <div className='text-xs text-danger position-absolute'>* Hay un error en el campo Provincia</div>
                            )}
                            <InputGroup className='mt-4'>
                              <InputGroupAddon addonType='prepend'>
                                <InputGroupText style={styles.boxLabel}>Ciudad</InputGroupText>
                              </InputGroupAddon>
                              <Input
                                name='city'
                                value={values.city}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder='Ciudad...'
                                type='text'
                              />
                            </InputGroup>
                            {errors.city && touched.city && (
                              <div className='text-xs text-danger position-absolute'>* Hay un error en el campo Ciudad</div>
                            )}
                            <InputGroup className='mt-4'>
                              <InputGroupAddon addonType='prepend'>
                                <InputGroupText style={styles.boxLabel}>Código Postal</InputGroupText>
                              </InputGroupAddon>
                              <Input
                                name='postal_code'
                                value={values.postal_code}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder='Código Postal...'
                                type='text'
                              />
                            </InputGroup>
                            {errors.postal_code && touched.postal_code && (
                              <div className='text-xs text-danger position-absolute'>* Hay un error en el campo Código Postal</div>
                            )}
                          </Col>
                        </Row>
                      </>
                    )}
                    <Button onClick={() => openUpdateBillingDetails()} className='float-right mt-4' color='link'>
                          Ver historial de facturación
                    </Button>
                  </CardBody>
                  <CardFooter className='d-flex justify-content-end'>
                    <Button
                      disabled={!isValid || isSubmitting}
                      type='submit'
                      color='primary'
                    >
                      {isSubmitting ? 'Cargando...' : 'Guardar'}
                    </Button>
                  </CardFooter>
                </Card>
              </Container>
            </form>

          )
        }}
      </Formik>

    </div>
  )
}
