import React, { useEffect, useContext, useState, useRef } from 'react'
// CSV
import { CSVLink } from 'react-csv'
// Firebase
import firebase from 'auth/Auth'
// Context
import UserContext from 'context/UserContext'
// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Row,
  Col,
  CardBody,
  CardFooter,
  Button
} from 'reactstrap'

export const AdvancedStats = () => {
  const context = useContext(UserContext)
  const [materials, setMaterials] = useState([])
  const [csvData, setCsvData] = useState([])
  const csvLink = useRef(null)

  const numberWithCommas = x => {
    var parts = x.toString().split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    return parts.join('.')
  }

  useEffect(() => {
    firebase.firestore()
      .collection('materials')
      .orderBy('usages', 'desc')
      .limit(10)
      .onSnapshot(async snapshot => {
        if (snapshot.docs.length > 0) {
          const allMaterials = []
          for (const material of snapshot.docs) {
            if (material.data().company) {
              const companyInfo = await firebase.firestore().collection('companies').doc(material.data().company).get()
              allMaterials.push({
                materialInfo: material?.data(),
                companyInfo: companyInfo?.data(),
                id: material.id,
                usages: material.data().usages
              })
            }
          }
          setMaterials(allMaterials)
        } else {
          setMaterials([])
        }
      })
  }, [context.company.slug])

  const getAllMaterials = () => {
    firebase.firestore()
      .collection('materials')
      .orderBy('usages', 'desc')
      .get()
      .then(async snapshot => {
        if (snapshot.docs.length > 0) {
          const allMaterials = []
          for (const material of snapshot.docs) {
            if (material.data().company) {
              const companyInfo = await firebase.firestore().collection('companies').doc(material.data().company).get()
              allMaterials.push({
                materialInfo: material?.data(),
                companyInfo: companyInfo?.data(),
                id: material.id,
                usages: material.data().usages
              })
            }
          }
          setCsvData(allMaterials.map(item => {
            return {
              Material: item.materialInfo.name,
              Empresa: item.companyInfo.name,
              Usos: item.usages
            }
          }))
        } else {
          setCsvData([])
        }
      })
  }

  useEffect(() => {
    if (csvData.length > 0) {
      setTimeout(() => {
        csvLink.current.link.click()
      }, 100)
    }
  }, [csvData])

  return (
    <Row>
      <Col>
        <div className='card-deck'>
          <Card className='bg-gradient-primary'>
            <CardHeader className='border-0 bg-transparent'>
              <Row className='align-items-center'>
                <div className='col'>
                  <h6 className='text-white text-uppercase ls-1 mb-1'>
                    Estadísticas avanzadas
                  </h6>
                  <p className='font-weight-normal mb-0'>Referencias más usadas en <b>Overlapp</b></p>
                </div>
              </Row>
            </CardHeader>
            <CardBody className='fixed-height-stats p-0'>
              <Table className='align-items-center table-flush' responsive>
                <thead className='bg-transparent'>
                  <tr style={{ backgroundColor: '#c2e484' }}>
                    <th scope='col'>Referencia</th>
                    <th scope='col'>Empresa</th>
                    <th scope='col'>Usos</th>
                  </tr>
                </thead>
                <tbody>
                  {materials.map(material => {
                    return (
                      <tr key={material.id}>
                        <th scope='row'>{material.materialInfo.name}</th>
                        <td>{material?.companyInfo?.name || '-'}</td>
                        <td className='text-white'>
                          <b>{numberWithCommas(material.usages)}</b>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </CardBody>
            <CardFooter className='bg-gradient-primary'>
              <Button
                className='float-right text-gray'
                color='link'
                onClick={async () => {
                  getAllMaterials()
                }}
              >
                <h6 className='text-gray text-uppercase ls-1 mb-1 float-right'>
                  Exportar
                  <i className='fas fa-file-export ml-1' />
                </h6>
              </Button>
              <CSVLink
                separator=';'
                data={csvData}
                filename='allReferences.csv'
                ref={csvLink}
                target='_blank'
              />
            </CardFooter>
          </Card>
        </div>
      </Col>
    </Row>
  )
}
