import React, { useState } from 'react'
// reactstrap components
import {
  Button,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert
} from 'reactstrap'
// core components
import AuthHeader from 'components/Headers/AuthHeader.js'
// Firebase
import firebase, { googleAuthProvider } from 'auth/Auth.js'
import { Formik } from 'formik'
// Formik
import * as Yup from 'yup'

const Login = (props) => {
  const [errorMsg, setErrorMsg] = useState(null)

  const signInWithPopup = () => {
    firebase.auth().signInWithPopup(googleAuthProvider).then(function (result) {
      props.history.push('/admin')
    }).catch(function (error) {
      console.log(error)
      setErrorMsg(error.message)
    })
  }

  return (
    <>
      <AuthHeader />
      {errorMsg && (
        <div style={{
          position: 'absolute',
          zIndex: '999',
          width: '100%'
        }}
        >
          <Alert className='mt-5 container' color='danger' fade>
            <span className='alert-inner--icon'>
              <i className='fas fa-exclamation-triangle' />
            </span>{' '}
            <span className='alert-inner--text'>
              <strong>Error: </strong> {errorMsg}
            </span>
            <button onClick={() => setErrorMsg(null)} type='button' className='close' aria-label='Close'>
              <span className='text-white' aria-hidden='true'>×</span>
            </button>
          </Alert>
        </div>
      )}
      <Container className='mt-8 pb-5'>
        <Row className='justify-content-center'>
          <Col lg='5' md='7'>
            <Formik
              initialValues={{ email: '', password: '', confirmPassword: '' }}
              onSubmit={async (values, { setSubmitting }) => {
                const { email, password } = values
                try {
                  await firebase
                    .auth()
                    .signInWithEmailAndPassword(email, password)
                } catch (error) {
                  console.log('Login error', error)
                  setErrorMsg(error.message)
                }
                setSubmitting(false)
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email()
                  .required('Email es obligatorio'),
                password: Yup.string()
                  .required('Password es obligatorio')
                  .min(6, 'Password demasiado corto')
              })}
            >
              {formikProps => {
                const {
                  values,
                  dirty,
                  isValid,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit
                } = formikProps
                return (
                  <form onSubmit={handleSubmit}>
                    <div className='text-center pb-4'>
                      <span className='h1'>
                        Inicia sesión con tu cuenta
                      </span>
                      <p className='text-gray'>Introduce tu correo electrónico y password</p>
                    </div>
                    <FormGroup>
                      <InputGroup className='input-group-alternative'>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText>
                            <i className='ni ni-email-83' />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          id='email'
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder='Email'
                          type='email'
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className='input-group-alternative'>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText>
                            <i className='ni ni-lock-circle-open' />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          id='password'
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder='Password'
                          type='password'
                        />
                      </InputGroup>
                    </FormGroup>
                    <span
                      style={{ cursor: 'pointer' }}
                      href='signup'
                      className='text-light text-xs float-right mb-4'
                      onClick={() => props.history.push('/auth/forgot-password')}
                    >
                      ¿Password olvidado?
                    </span>
                    <div className='text-center'>
                      <Button block disabled={!dirty || isSubmitting || !isValid} className='my-4' color='primary' type='submit'>
                        INICIAR SESIÓN
                      </Button>
                      <Container>
                        <Row className='align-items-center'>
                          <Col>
                            <hr />
                          </Col>
                          <Col lg={{ size: 'auto' }}>
                            <span className='text-light text-sm'>O inicia sesión con</span>
                          </Col>
                          <Col>
                            <hr />
                          </Col>
                        </Row>
                      </Container>
                      <Button
                        block
                        className='btn-icon'
                        color='white'
                        type='button'
                        onClick={() => signInWithPopup()}
                      >
                        <span className='btn-inner--icon'>
                          <img className='firebaseui-idp-icon' alt='' src='https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg' />
                        </span>
                        <span className='btn-inner--text text-gray'>INICIAR SESIÓN CON GOOGLE</span>
                      </Button>
                    </div>
                  </form>
                )
              }}
            </Formik>
            <Row className='mt-3'>
              <Col className='text-center mt-6'>
                <span
                  className='text-light'
                >
                  <small>¿No tienes una cuenta?{' '}
                    <span
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                      href='signup'
                      className='text-primary'
                      onClick={() => props.history.push('/auth/signup')}
                    >Crear una
                    </span>
                  </small>
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Login
