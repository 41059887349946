import React from 'react'

export default function Loader () {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh'
      }}
    >
      <img className='animate-logo' width='100' alt='logo' src={require('assets/img/brand/logo-circle.png')} />
    </div>
  )
}
