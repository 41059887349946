import React from 'react'
// used for making the prop types of this component
import PropTypes from 'prop-types'

// core components
import { Button } from 'reactstrap'

import defaultImage from 'assets/img/default-placeholder.png'
import './ImageUpload.css'

class ImageUpload extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      file: null,
      imagePreviewUrl: this.props.preview || defaultImage
    }
    this.handleImageChange = this.handleImageChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleRemove = this.handleRemove.bind(this)
  }

  componentDidUpdate (prevProps) {
    if (prevProps.preview !== this.props.preview) {
      this.setState({ imagePreviewUrl: this.props.preview })
    }
  }

  handleImageChange (e) {
    e.preventDefault()
    const reader = new FileReader()
    const file = e.target.files[0]
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      }, () => this.props.onChange(this.state.file, this.state.imagePreviewUrl))
    }
    reader.readAsDataURL(file)
  }

  handleClick () {
    this.refs.fileInput.click()
  }

  handleRemove () {
    this.setState({
      file: null,
      imagePreviewUrl: defaultImage
    }, () => this.props.onChange(this.state.file, this.state.imagePreviewUrl))
    this.refs.fileInput.value = null
  }

  render () {
    const {
      avatar,
      fullWidth,
      addButtonProps,
      changeButtonProps,
      removeButtonProps
    } = this.props

    if (this.props.children) {
      return (
        <div className='fileinput text-center'>
          <input
            onChange={this.handleImageChange}
            ref='fileInput'
            type='file'
            accept='image/x-png,image/jpeg'
          />
          <div>
            <Button color='link' {...addButtonProps} onClick={() => this.handleClick()}>
              {this.props.children}
            </Button>
          </div>
        </div>
      )
    } else {
      return (
        <div className='fileinput text-center'>
          <input
            onChange={this.handleImageChange}
            ref='fileInput'
            type='file'
            accept='image/x-png,image/jpeg'
          />
          <div className={'thumbnail' + (avatar ? ' img-circle' : '') + (fullWidth ? ' full-width' : '')}>
            <img src={this.state.imagePreviewUrl} alt='...' />
          </div>
          <div>
            {this.state.file === null ? (
              <Button color='link text-light' {...addButtonProps} onClick={() => this.handleClick()}>
                {this.props.preview ? 'Reemplazar imagen' : 'Seleccionar imagen'}
              </Button>
            ) : (
              <span>
                <Button color='link text-light' {...changeButtonProps} onClick={() => this.handleClick()}>
                  Reemplazar imagen
                </Button>
                {avatar ? <br /> : null}
                {/* <Button
                  color='link text-danger'
                  {...removeButtonProps}
                  onClick={() => this.handleRemove()}
                >
                  <i className='fas fa-times' />{' '} Eliminar
                </Button> */}
              </span>
            )}
          </div>
        </div>
      )
    }
  }
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object
}

export default ImageUpload
