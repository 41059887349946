import React, { useState } from 'react'
// reactstrap components
import {
  Button,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert
} from 'reactstrap'
// core components
import AuthHeader from 'components/Headers/AuthHeader.js'
import Checkbox from 'components/Checkbox/Checkbox.js'
// Firebase
import firebase, { googleAuthProvider } from 'auth/Auth.js'
import { Formik } from 'formik'
// Formik
import * as Yup from 'yup'

const Signup = (props) => {
  const [errorMsg, setErrorMsg] = useState(null)
  const [terms, setTerms] = useState(false)

  const signInWithPopup = () => {
    firebase.auth().signInWithPopup(googleAuthProvider).then(function (result) {
      props.history.push('/admin')
    }).catch(function (error) {
      console.log(error)
      setErrorMsg(error.message)
    })
  }

  return (
    <>
      <AuthHeader />
      {errorMsg && (
        <div style={{
          position: 'absolute',
          zIndex: '999',
          width: '100%'
        }}
        >
          <Alert className='mt-5 container' color='danger' fade>
            <span className='alert-inner--icon'>
              <i className='fas fa-exclamation-triangle' />
            </span>{' '}
            <span className='alert-inner--text'>
              <strong>Error: </strong> {errorMsg}
            </span>
            <button onClick={() => setErrorMsg(null)} type='button' className='close' aria-label='Close'>
              <span className='text-white' aria-hidden='true'>×</span>
            </button>
          </Alert>
        </div>
      )}
      <Container className='mt-8 pb-5'>
        <div className='text-center pb-4'>
          <span className='h1'>
            Bienvenido a <span className='text-primary'>Overlapp</span>
          </span>
          <p className='text-gray'>Regístrate con tu correo electrónico y password</p>
        </div>
        <Row className='justify-content-center'>
          <Col lg='5' md='7'>
            <Formik
              initialValues={{ email: '', password: '', passwordConfirm: '' }}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                const { email, password } = values
                try {
                  await firebase.auth().createUserWithEmailAndPassword(email, password)
                    .catch(function (error) {
                      console.log(error)
                      setErrorMsg(error.message)
                    })
                } catch (error) {
                  console.log('Login error', error)
                }
                setSubmitting(false)
                resetForm({})
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email()
                  .required('No email provided.'),
                password: Yup.string()
                  .required('No password provided.')
                  .min(6, 'Password is too short - should be 6 chars minimum.'),
                passwordConfirm: Yup.string()
                  .oneOf([Yup.ref('password'), null], 'Passwords deben coincidir')
                  .required('Confirmar password es obligatorio')
              })}
            >
              {formikProps => {
                const {
                  values,
                  dirty,
                  isValid,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit
                } = formikProps
                return (
                  <form onSubmit={handleSubmit}>
                    <FormGroup>
                      <InputGroup className='input-group-alternative'>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText>
                            <i className='ni ni-email-83' />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          id='email'
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder='Email'
                          type='email'
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className='input-group-alternative'>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText>
                            <i className='ni ni-lock-circle-open' />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          id='password'
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder='Password'
                          type='password'
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className='input-group-alternative'>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText>
                            <i className='ni ni-lock-circle-open' />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          id='passwordConfirm'
                          value={values.passwordConfirm}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder='Repite password'
                          type='password'
                        />
                      </InputGroup>

                      <label className='float-right mt-4 mb-4'>
                        <Checkbox
                          checked={terms}
                          onChange={() => setTerms(!terms)}
                        />
                        <small className='text-light ml-2'>
                          Acepto los <a href='https://admin.io/terms' target='_blank' rel='noopener noreferrer'>Términos y Condiciones</a>
                        </small>
                      </label>

                    </FormGroup>
                    <div className='text-center'>
                      <Button block disabled={!dirty || isSubmitting || !isValid || !terms} className='my-4' color='primary' type='submit'>
                        CREAR CUENTA
                      </Button>
                      <Container>
                        <Row className='align-items-center'>
                          <Col>
                            <hr />
                          </Col>
                          <Col lg={{ size: 'auto' }}>
                            <span className='text-light text-sm'>O regístrate con</span>
                          </Col>
                          <Col>
                            <hr />
                          </Col>
                        </Row>
                      </Container>
                      <Button
                        block
                        className='btn-icon'
                        color='white'
                        type='button'
                        disabled={!terms}
                        onClick={() => signInWithPopup()}
                      >
                        <span className='btn-inner--icon'>
                          <img className='firebaseui-idp-icon' alt='' src='https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg' />
                        </span>
                        <span className='btn-inner--text text-gray'>CREAR CUENTA CON GOOGLE</span>
                      </Button>
                    </div>
                  </form>
                )
              }}
            </Formik>
            <Row className='mt-3'>
              <Col className='text-center mt-6'>
                <span
                  className='text-light'
                >
                  <small>¿Ya tienes una cuenta?{' '}
                    <span
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                      href='signup'
                      className='text-primary'
                      onClick={() => props.history.push('/auth/login')}
                    >Iniciar sesión
                    </span>
                  </small>
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Signup
