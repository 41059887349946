import Dashboard from 'views/Dashboard.js'
import Stats from 'views/Stats.js'
import Notifications from 'views/Notifications.js'
import UpgradePlan from 'views/UpgradePlan.js'
import Materials from 'views/Materials.js'
import Company from 'views/Company.js'

const routes = [
  {
    path: '/upgrade-plan',
    name: 'Upgrade Plan',
    miniName: 'P',
    component: UpgradePlan,
    layout: '/admin',
    invisible: true
  },
  {
    path: '/stats',
    name: 'App Stats',
    icon: 'ni ni-chart-bar-32 text-primary',
    component: Stats,
    layout: '/admin'
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'ni ni-chart-pie-35 text-primary',
    component: Dashboard,
    layout: '/admin'
  },
  {
    path: '/references',
    name: 'Referencias',
    icon: 'ni ni-ungroup text-primary',
    component: Materials,
    layout: '/admin'
  },
  {
    path: '/notifications',
    name: 'Notificaciones',
    icon: 'ni ni-send text-primary',
    component: Notifications,
    layout: '/admin'
  },
  {
    path: '/company',
    name: 'Perfil Empresa',
    icon: 'ni ni-briefcase-24 text-primary',
    component: Company,
    layout: '/admin',
    invisible: true
  }
]

export default routes
