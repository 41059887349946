export const PROFESSIONS = {
  architect: {
    name: 'Arquitecto',
    color: '#FF8873'
  },
  decorator: {
    name: 'Decorador',
    color: '#68EDCB'
  },
  reformist: {
    name: 'Reformista',
    color: '#B7D679'
  },
  particular: {
    name: 'Particular',
    color: '#FFD17E'
  },
  interiorist: {
    name: 'Interiorista',
    color: '#00AABE'
  }
}
