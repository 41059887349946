import React from 'react'
// Component
import PricingTable from 'components/Pricing/Pricing'
// reactstrap components
import { Container } from 'reactstrap'
// core components
import AuthHeader from 'components/Headers/AuthHeader.js'

function Pricing (props) {
  return (
    <>
      <AuthHeader title='Choose the best plan for your business' lead='' />
      <Container fluid>
        <PricingTable />
      </Container>
    </>
  )
}

export default Pricing
