import React from 'react'
// Firebase
import firebase from 'auth/Auth'
// react library for routing
import { Route, Switch, Redirect } from 'react-router-dom'
// core components
import Loader from 'components/Loader/Loader'
import CustomerInfo from 'components/CustomerInfo/CustomerInfo.js'
import Pricing from 'components/Pricing/Pricing.js'
import AdminNavbar from 'components/Navbars/AdminNavbar.js'
import ConfigNavbar from 'components/Navbars/ConfigNavbar.js'
import AdminFooter from 'components/Footers/AdminFooter.js'
import Sidebar from 'components/Sidebar/Sidebar.js'
import { Tutorial } from 'components/Tutorial/Tutorial'
// Context
import UserContext from 'context/UserContext'
// Routes
import adminRoutes from 'routes/admin'
import guestRoutes from 'routes/guest'
import superAdminRoutes from 'routes/superAdmin'
// Helmet
import { Helmet } from 'react-helmet'
// Config
import { PLANS } from 'config/stripe'
// underscore
import _ from 'underscore'

class Admin extends React.Component {
  state = {
    sidenavOpen: true,
    guestUser: null,
    user: null,
    materials: 0,
    showModalSubscription: false,
    loading: true,
    subscription: null,
    companyInfo: null,
    stripe: null,
    showedTutorial: true,
    setLoading: (newState) => { this.setState({ loading: newState }) }
  }

  checkCompanyInfo = customerInfo => {
    if (customerInfo.exists && customerInfo.data().company) {
      firebase.firestore()
        .collection('companies')
        .doc(customerInfo.data().company)
        .onSnapshot(snapshotCompany => {
          if (snapshotCompany.data()) {
            firebase.firestore()
              .collection('materials')
              .where('company', '==', snapshotCompany.id)
              .onSnapshot(snapshotMaterials => {
                if (snapshotMaterials.docs.length > 0) {
                  this.setState({
                    materials: snapshotMaterials.docs.map(material => {
                      return {
                        ...material.data(),
                        materialId: material.id
                      }
                    })
                  })
                } else {
                  this.setState({ materials: [] })
                }
              })
            this.setState({
              companyInfo: {
                ...snapshotCompany.data(),
                id: snapshotCompany.id
              }
            })
          } else {
            this.setState({ companyInfo: null })
          }
        })
    } else {
      this.setState({ companyInfo: null })
    }
  }

  checkSubscription = (uid, overrideMaterials) => {
    firebase.firestore()
      .collection('customers')
      .doc(uid)
      .collection('subscriptions')
      .where('status', 'in', ['trialing', 'active'])
      .onSnapshot(async (snapshotSubscription) => {
        if (snapshotSubscription.empty) {
          this.setState({ loading: false, subscription: null })
        } else {
          const priceInfo = await snapshotSubscription.docs[0].data().price.get()
          let plan = null
          Object.keys(PLANS).forEach(planId => {
            if (_.findWhere(PLANS[planId].prices, { id: priceInfo.id })) {
              plan = {
                ...PLANS[planId],
                name: planId
              }
            }
          })
          if (plan) {
            if (overrideMaterials) {
              plan.materials = overrideMaterials
            }
            this.setState({
              loading: false,
              subscription: true,
              stripe: {
                subscription: {
                  ...snapshotSubscription.docs[0].data(),
                  id: snapshotSubscription.docs[0].id
                },
                plan
              }
            })
          } else {
            const functionRef = firebase
              .functions('europe-west3')
              .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink')
            const { data } = await functionRef({ returnUrl: window.location.origin })

            window.location.href = data.url
          }
        }
      })
  }

  componentWillMount () {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        let guestUser = null
        let adminUser = null
        // Check if user is a guest
        const guestInfo = await firebase.firestore()
          .collection('guests')
          .doc(user.uid)
          .get()

        if (guestInfo.exists) {
          // Get admin user of the guest
          var getUser = firebase.functions().httpsCallable('getUser')
          const userInfo = await getUser({ uid: guestInfo.data().customerId })
          if (userInfo.data) {
            adminUser = userInfo.data
            guestUser = {
              ...user,
              ...guestInfo.data()
            }
          }
        }

        firebase.firestore()
          .collection('customers')
          .doc(adminUser ? adminUser.uid : user.uid)
          .onSnapshot(snapshot => {
            if (snapshot.exists) {
              this.setState({
                user: {
                  ...(guestUser ? adminUser : user),
                  data: snapshot.data()
                },
                guestUser
              })

              // Check Company Info
              this.checkCompanyInfo(snapshot)

              // Check if this user has an adjustment materials
              let overrideMaterials = false
              if (snapshot.data().materials) {
                overrideMaterials = snapshot.data().materials
              }

              // Check Subscription
              this.checkSubscription(
                adminUser ? adminUser.uid : user.uid, overrideMaterials
              )

              if (!snapshot.data()?.showedTutorial) {
                this.setState({ showedTutorial: false })
              } else {
                this.setState({ showedTutorial: true })
              }
            } else {
              this.setState({ user: (guestUser ? adminUser : user), loading: false, subscription: null, companyInfo: null })
            }
          })
      } else {
        this.setState({ user: null, loading: false, subscription: null, companyInfo: null })
        this.props.history.push('/auth/login')
      }
    })
  }

  componentDidUpdate (e) {
    // if (e.history.pathname !== e.location.pathname) {
    //   document.documentElement.scrollTop = 0
    //   document.scrollingElement.scrollTop = 0
    //   this.refs.mainContent.scrollTop = 0
    // }
  }

  getUserRoutes = () => {
    const role = this.getRole()
    console.log(role)
    if (role === 'admin') {
      return this.getRoutes(adminRoutes)
    } else if (role === 'guest') {
      return this.getRoutes(guestRoutes)
    } else if (role === 'superAdmin') {
      return this.getRoutes(superAdminRoutes)
    } else {
      return null
    }
  }

  getSidebarRoutes = () => {
    const role = this.getRole()
    console.log(role)
    if (role === 'admin') {
      return adminRoutes
    } else if (role === 'guest') {
      return guestRoutes
    } else if (role === 'superAdmin') {
      return superAdminRoutes
    } else {
      return null
    }
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views)
      }
      if (prop.layout === '/admin') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  }

  getRole = () => {
    if (this.state.guestUser) {
      return 'guest'
    } else {
      if (this.state.companyInfo.id === 'estudio-cactus') {
        return 'superAdmin'
      } else {
        return 'admin'
      }
    }
  }

  // getBrandText = path => {
  //   for (let i = 0; i < adminRoutes.length; i++) {
  //     if (
  //       this.props.location.pathname.indexOf(
  //         adminRoutes[i].layout + adminRoutes[i].path
  //       ) !== -1
  //     ) {
  //       return adminRoutes[i].name
  //     }
  //   }
  //   return 'Brand'
  // }

  // toggles collapse between mini sidenav and normal
  toggleSidenav = e => {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned')
      document.body.classList.add('g-sidenav-hidden')
    } else {
      document.body.classList.add('g-sidenav-pinned')
      document.body.classList.remove('g-sidenav-hidden')
    }
    this.setState({
      sidenavOpen: !this.state.sidenavOpen
    })
  }

  getNavbarTheme = () => {
    return this.props.location.pathname.indexOf(
      'admin/alternative-dashboard'
    ) === -1
      ? 'dark'
      : 'light'
  }

  render () {
    if (this.state.loading || !this.state.user) {
      return (
        <div
          className='main-content'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh'
          }}
          ref='mainContent'
        >
          <Loader />
        </div>
      )
    } else {
      return (
        <UserContext.Provider
          value={{
            user: this.state.user,
            guestUser: this.state.guestUser,
            company: this.state.companyInfo,
            materials: this.state.materials,
            stripe: this.state.stripe,
            setLoading: this.state.setLoading
          }}
        >
          <>
            {!this.state.subscription ? (
              <div
                className='main-content'
                ref='mainContent'
                onClick={this.closeSidenav}
              >
                <ConfigNavbar
                  {...this.props}
                  theme={this.getNavbarTheme()}
                  toggleSidenav={this.toggleSidenav}
                  sidenavOpen={this.state.sidenavOpen}
                  // brandText={this.getBrandText(this.props.location.pathname)}
                />
                <Pricing />
              </div>
            ) : !this.state.companyInfo ? (
              <div
                className='main-content'
                ref='mainContent'
                onClick={this.closeSidenav}
              >
                <ConfigNavbar
                  {...this.props}
                  theme={this.getNavbarTheme()}
                  toggleSidenav={this.toggleSidenav}
                  sidenavOpen={this.state.sidenavOpen}
                  // brandText={this.getBrandText(this.props.location.pathname)}
                />
                <CustomerInfo />
              </div>
            ) : (
              <>
                <Tutorial
                  toggle={(notshow) => {
                    if (notshow) {
                      firebase.firestore()
                        .collection('customers')
                        .doc(this.state.user.uid)
                        .update({
                          showedTutorial: true
                        })
                    } else {
                      this.setState({ showedTutorial: !this.state.showedTutorial })
                    }
                  }}
                  modal={!this.state.showedTutorial}
                />
                <Helmet
                  script={[
                    {
                      type: 'text/javascript',
                      innerHTML: `
                        window.fcWidget.setExternalId("${this.state.user.uid}");
                        window.fcWidget.user.setFirstName("${this.state.user.displayName}");
                        window.fcWidget.user.setEmail("${this.state.user.email}");
                        
                    `
                    }
                  ]}
                />
                <Sidebar
                  {...this.props}
                  routes={this.getSidebarRoutes()}
                  toggleSidenav={this.toggleSidenav}
                  sidenavOpen={this.state.sidenavOpen}
                  logo={{
                    innerLink: '/',
                    imgSrc: require('assets/img/brand/logo-sidebar.svg'),
                    imgAlt: '...'
                  }}
                />
                <div
                  className='main-content'
                  ref='mainContent'
                  onClick={this.closeSidenav}
                >
                  <AdminNavbar
                    {...this.props}
                    theme={this.getNavbarTheme()}
                    toggleSidenav={this.toggleSidenav}
                    sidenavOpen={this.state.sidenavOpen}
                    // brandText={this.getBrandText(this.props.location.pathname)}
                  />
                  <Switch>
                    {this.getUserRoutes()}
                    <Redirect from='*' to='/admin/references' />
                  </Switch>
                  <AdminFooter />
                </div>
              </>
            )}
          </>
          {this.state.sidenavOpen ? (
            <div className='backdrop d-xl-none' onClick={this.toggleSidenav} />
          ) : null}
        </UserContext.Provider>
      )
    }
  }
}

export default Admin
