import fb from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'

const config = {
  apiKey: 'AIzaSyBkP-25mvNCGxAlPDAI3hlgjjH8nCS2leg',
  authDomain: 'overlapp-estudiocactus.firebaseapp.com',
  databaseURL: 'https://overlapp-estudiocactus.firebaseio.com',
  projectId: 'overlapp-estudiocactus',
  storageBucket: 'overlapp-estudiocactus.appspot.com',
  messagingSenderId: '887868962047',
  appId: '1:887868962047:web:b2b9c9e9cf9063a7ee48ca',
  measurementId: 'G-W30CHKQ41E'
}

const googleAuthProvider = new fb.auth.GoogleAuthProvider()
googleAuthProvider.setCustomParameters({
  prompt: 'select_account'
})
const firebase = fb.initializeApp(config)

export default firebase
export { googleAuthProvider }
