import React, { useState, useContext } from 'react'
// Router
import { useHistory } from 'react-router-dom'
// // Firebase
import firebase from 'auth/Auth'
// Context
import UserContext from 'context/UserContext'
// Stripe
import { loadStripe } from '@stripe/stripe-js'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Table,
  Container,
  Row,
  Col
} from 'reactstrap'

import { STRIPE_PUBLISHABLE_KEY, taxRates, PLANS } from 'config/stripe'

function Pricing (props) {
  const history = useHistory()
  const context = useContext(UserContext)
  const [yearly, setYearly] = useState(true)

  const subscribe = async priceID => {
    if (context?.user.uid) {
      context.setLoading(true)
      const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY)
      const docRef = await firebase
        .firestore()
        .collection('customers')
        .doc(context.user.uid)
        .collection('checkout_sessions')
        .add({
          price: priceID,
          allow_promotion_codes: true,
          trial_from_plan: false,
          billing_address_collection: 'auto',
          tax_rates: taxRates,
          success_url: window.location.origin,
          cancel_url: window.location.origin
        })

      docRef.onSnapshot(snap => {
        const { error, sessionId } = snap.data()
        if (error) {
          console.log(`An error occured: ${error.message}`)
        }
        if (sessionId) {
          stripe.redirectToCheckout({ sessionId })
        }
      })
    } else {
      history.push('/auth')
    }
  }

  const startTrial = async trialId => {
    context.setLoading(true)
    const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY)
    const docRef = await firebase
      .firestore()
      .collection('customers')
      .doc(context.user.uid)
      .collection('checkout_sessions')
      .add({
        price: trialId,
        allow_promotion_codes: true,
        trial_from_plan: true,
        billing_address_collection: 'auto',
        tax_rates: taxRates,
        success_url: window.location.origin,
        cancel_url: window.location.origin
      })

    docRef.onSnapshot(snap => {
      const { error, sessionId } = snap.data()
      if (error) {
        console.log(`An error occured: ${error.message}`)
      }
      if (sessionId) {
        stripe.redirectToCheckout({ sessionId })
      }
    })
  }

  const openUpdateSubscription = async priceId => {
    context.setLoading(true)
    const functionRef = firebase
      .functions('europe-west3')
      .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink')
    const { data } = await functionRef({ returnUrl: window.location.origin })

    window.location.href =
      data.url +
      `/subscriptions/${context.stripe.subscription.id}/preview/${priceId}`
  }

  return (
    <div>
      <Container fluid className='pt-8'>
        <Row className='mb-6 pl-4 pr-4'>
          <Col>
            <label className='custom-toggle mr-1'>
              <input
                onClick={() => setYearly(!yearly)}
                checked={yearly}
                type='checkbox'
              />
              <span className='custom-toggle-slider rounded-circle' />
            </label>
            <span
              className={(yearly ? 'text-primary' : 'text-light') + ' ml-3'}
            >
              Facturado {yearly ? 'anualmente' : 'mensualmente'}
              {yearly && (
                <small className='text-gray ml-3'>Ahorra 2 meses</small>
              )}
            </span>
          </Col>
          {/* <Col>
            <p
              style={{ cursor: 'pointer' }}
              onClick={() => startTrial(PLANS.pro.prices.yearly.id)}
              className='mt-2 float-right'
            >
              <u>Suscribirse a la promoción FREE2021</u>
            </p>
          </Col> */}
        </Row>
        <Row className='justify-content-center'>
          <Col lg='12'>
            <div className='pricing card-group flex-column flex-md-row mb-3'>
              <Card className='card-pricing border-0 text-center mb-4'>
                <CardHeader className='bg-transparent'>
                  <h4 className='text-uppercase ls-1 text-primary py-3 mb-0'>
                    Plan Basic
                  </h4>
                </CardHeader>
                <CardBody className='px-lg-4'>
                  <div className='display-2'>
                    {yearly
                      ? PLANS.basic.prices.yearly.price
                      : PLANS.basic.prices.monthly.price}
                    €
                  </div>
                  <span className='text-muted'>al mes</span>
                  <p className='text-sm text-light'>IVA incluido</p>
                  <ul className='list-unstyled my-4'>
                    <li className='p-0'>
                      <div className='d-flex align-items-center'>
                        <div>
                          <div className='icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white'>
                            <i className='fas fa-layer-group' />
                          </div>
                        </div>
                        <div>
                          <h3 className='pl-2 pt-2'>
                            <small>25 referencias</small>
                          </h3>
                        </div>
                      </div>
                    </li>
                    <li className='p-0'>
                      <div className='d-flex align-items-center'>
                        <div>
                          <div className='icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white'>
                            <i className='fas fa-bullseye' />
                          </div>
                        </div>
                        <div>
                          <h3 className='pl-2 pt-2'>
                            <small>Posicionamiento app BASIC</small>
                          </h3>
                        </div>
                      </div>
                    </li>
                    <li className='p-0'>
                      <div className='d-flex align-items-center'>
                        <div>
                          <div className='icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white'>
                            <i className='fas fa-chart-bar' />
                          </div>
                        </div>
                        <div>
                          <h3 className='pl-2 pt-2'>
                            <small>Estadísticas de tendencias</small>
                          </h3>
                        </div>
                      </div>
                    </li>
                    <li className='p-0'>
                      <div className='d-flex align-items-center'>
                        <div>
                          <div className='icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white'>
                            <i className='fas fa-pen-fancy' />
                          </div>
                        </div>
                        <div>
                          <h3 className='pl-2 pt-2'>
                            <small>
                              Recibe emails de clientes potenciales{' '}
                            </small>
                          </h3>
                        </div>
                      </div>
                    </li>
                    <li className='p-0'>
                      <div className='d-flex align-items-center'>
                        <div>
                          <div className='icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white'>
                            <i className='fas fa-pen-fancy' />
                          </div>
                        </div>
                        <div>
                          <h3 className='pl-2 pt-2'>
                            <small>Asistencia técnica</small>
                          </h3>
                        </div>
                      </div>
                    </li>
                  </ul>
                  {context &&
                  context.stripe &&
                  context.stripe.plan &&
                  context.stripe.plan.id === PLANS.basic.id ? (
                    <b className='text-light'>Plan Actual</b>
                  ) : (
                    <>
                      <Button
                        className='mb-3'
                        color='primary'
                        type='button'
                        onClick={() => {
                          if (props.upgrade) {
                            openUpdateSubscription(
                              yearly
                                ? PLANS.basic.prices.yearly.id
                                : PLANS.basic.prices.monthly.id
                            )
                          } else {
                            subscribe(
                              yearly
                                ? PLANS.basic.prices.yearly.id
                                : PLANS.basic.prices.monthly.id
                            )
                          }
                        }}
                      >
                        {props.upgrade ? 'Actualizar' : 'Iniciar'} Plan
                      </Button>
                      {!props.upgrade && (
                        <p
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            startTrial(
                              yearly
                                ? PLANS.basic.prices.yearly.id
                                : PLANS.basic.prices.monthly.id
                            )
                          }
                          className='mt-2'
                        >
                          <u>Iniciar prueba gratuita de 30 días</u>
                        </p>
                      )}
                    </>
                  )}
                </CardBody>
                <CardFooter>
                  <a className='text-light' href='#features'>
                    Ver características
                  </a>
                </CardFooter>
              </Card>
              <Card className='card-pricing bg-gradient-primary zoom-in shadow-lg rounded border-0 text-center mb-4'>
                <CardHeader className='bg-transparent'>
                  <h4 className='text-uppercase ls-1 text-white py-3 mb-0'>
                    Plan Pro
                    <br />
                    <small className='text-capitalize'>
                      <i className='fas fa-star mr-2' />
                      Más popular
                    </small>
                  </h4>
                </CardHeader>
                <CardBody className='px-lg-4'>
                  <div className='display-1 text-white'>
                    {yearly
                      ? PLANS.pro.prices.yearly.price
                      : PLANS.pro.prices.monthly.price}
                    €
                  </div>
                  <span className='text-white'>al mes</span>
                  <p className='text-sm text-white'>IVA incluido</p>
                  <ul className='list-unstyled my-4'>
                    <li className='p-0'>
                      <div className='d-flex align-items-center'>
                        <div>
                          <div className='icon icon-xs icon-shape bg-white shadow rounded-circle text-muted'>
                            <i className='fas fa-plus' />
                          </div>
                        </div>
                        <div>
                          <h3 className='pl-2 pt-2 text-white'>
                            <small>
                              Todo lo incluido en el plan <b>BASIC</b>
                            </small>
                          </h3>
                        </div>
                      </div>
                    </li>
                    <li className='p-0'>
                      <div className='d-flex align-items-center'>
                        <div>
                          <div className='icon icon-xs icon-shape bg-white shadow rounded-circle text-muted'>
                            <i className='fas fa-layer-group' />
                          </div>
                        </div>
                        <div>
                          <h3 className='pl-2 pt-2 text-white'>
                            <small>50 referencias</small>
                          </h3>
                        </div>
                      </div>
                    </li>
                    <li className='p-0'>
                      <div className='d-flex align-items-center'>
                        <div>
                          <div className='icon icon-xs icon-shape bg-white shadow rounded-circle text-muted'>
                            <i className='fas fa-bullseye' />
                          </div>
                        </div>
                        <div>
                          <h3 className='pl-2 pt-2 text-white'>
                            <small>Posicionamiento app PRO</small>
                          </h3>
                        </div>
                      </div>
                    </li>
                    <li className='p-0'>
                      <div className='d-flex align-items-center'>
                        <div>
                          <div className='icon icon-xs icon-shape bg-white shadow rounded-circle text-muted'>
                            <i className='fas fa-chart-bar' />
                          </div>
                        </div>
                        <div>
                          <h3 className='pl-2 pt-2 text-white'>
                            <small>Estadísticas propias</small>
                          </h3>
                        </div>
                      </div>
                    </li>
                    <li className='p-0'>
                      <div className='d-flex align-items-center'>
                        <div>
                          <div className='icon icon-xs icon-shape bg-white shadow rounded-circle text-muted'>
                            <i className='fas fa-users' />
                          </div>
                        </div>
                        <div>
                          <h3 className='pl-2 pt-2 text-white'>
                            <small>Interacciones con mis referencias</small>
                          </h3>
                        </div>
                      </div>
                    </li>
                    <li className='p-0'>
                      <div className='d-flex align-items-center'>
                        <div>
                          <div className='icon icon-xs icon-shape bg-white shadow rounded-circle text-muted'>
                            <i className='fas fa-envelope' />
                          </div>
                        </div>
                        <div>
                          <h3 className='pl-2 pt-2 text-white'>
                            <small>Reporte mensual por email</small>
                          </h3>
                        </div>
                      </div>
                    </li>
                  </ul>
                  {context &&
                  context.stripe &&
                  context.stripe.plan &&
                  context.stripe.plan.id === PLANS.pro.id ? (
                    <b className='text-white'>Plan Actual</b>
                  ) : (
                    <Button
                      className='mb-3'
                      color='secondary'
                      type='button'
                      onClick={() => {
                        if (props.upgrade) {
                          openUpdateSubscription(
                            yearly
                              ? PLANS.pro.prices.yearly.id
                              : PLANS.pro.prices.monthly.id
                          )
                        } else {
                          subscribe(
                            yearly
                              ? PLANS.pro.prices.yearly.id
                              : PLANS.pro.prices.monthly.id
                          )
                        }
                      }}
                    >
                      {props.upgrade ? 'Actualizar' : 'Iniciar'} Plan
                    </Button>
                  )}
                </CardBody>
                <CardFooter className='bg-transparent'>
                  <a className='text-white' href='#features'>
                    Ver características
                  </a>
                </CardFooter>
              </Card>
              <Card className='card-pricing border-0 text-center mb-4'>
                <CardHeader className='bg-transparent'>
                  <h4 className='text-uppercase ls-1 text-primary py-3 mb-0'>
                    Plan Premium
                  </h4>
                </CardHeader>
                <CardBody className='px-lg-4 ml-3'>
                  <div className='display-2'>
                    {yearly
                      ? PLANS.premium.prices.yearly.price
                      : PLANS.premium.prices.monthly.price}
                    €
                  </div>
                  <span className='text-muted'>al mes</span>
                  <p className='text-sm text-light'>IVA incluido</p>
                  <ul className='list-unstyled my-4'>
                    <li className='p-0'>
                      <div className='d-flex align-items-center'>
                        <div>
                          <div className='icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white'>
                            <i className='fas fa-plus' />
                          </div>
                        </div>
                        <div>
                          <h3 className='pl-2 pt-2'>
                            <small>
                              Todo lo incluido en el plan <b>PRO</b>
                            </small>
                          </h3>
                        </div>
                      </div>
                    </li>
                    <li className='p-0'>
                      <div className='d-flex align-items-center'>
                        <div>
                          <div className='icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white'>
                            <i className='fas fa-layer-group' />
                          </div>
                        </div>
                        <div>
                          <h3 className='pl-2 pt-2'>
                            <small>100 referencias</small>
                          </h3>
                        </div>
                      </div>
                    </li>
                    <li className='p-0'>
                      <div className='d-flex align-items-center'>
                        <div>
                          <div className='icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white'>
                            <i className='fas fa-bullseye' />
                          </div>
                        </div>
                        <div>
                          <h3 className='pl-2 pt-2'>
                            <small>Posicionamiento app PREMIUM</small>
                          </h3>
                        </div>
                      </div>
                    </li>
                    <li className='p-0'>
                      <div className='d-flex align-items-center'>
                        <div>
                          <div className='icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white'>
                            <i className='fas fa-chart-bar' />
                          </div>
                        </div>
                        <div>
                          <h3 className='pl-2 pt-2'>
                            <small>Estadísticas avanzadas</small>
                          </h3>
                        </div>
                      </div>
                    </li>
                    <li className='p-0'>
                      <div className='d-flex align-items-center'>
                        <div>
                          <div className='icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white'>
                            <i className='fas fa-paper-plane' />
                          </div>
                        </div>
                        <div>
                          <h3 className='pl-2 pt-2'>
                            <small>2 notificaciones segmentadas/mes</small>
                          </h3>
                        </div>
                      </div>
                    </li>
                    <li className='p-0'>
                      <div className='d-flex align-items-center'>
                        <div>
                          <div className='icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white'>
                            <i className='fas fa-tag' />
                          </div>
                        </div>
                        <div>
                          <h3 className='pl-2 pt-2'>
                            <small>Publicación RRSS Overlapp</small>
                          </h3>
                        </div>
                      </div>
                    </li>
                  </ul>
                  {context &&
                  context.stripe &&
                  context.stripe.plan &&
                  context.stripe.plan.id === PLANS.premium.id ? (
                    <b className='text-light'>Plan Actual</b>
                  ) : (
                    <Button
                      className='mb-3'
                      color='primary'
                      type='button'
                      onClick={() => {
                        if (props.upgrade) {
                          openUpdateSubscription(
                            yearly
                              ? PLANS.premium.prices.yearly.id
                              : PLANS.premium.prices.monthly.id
                          )
                        } else {
                          subscribe(
                            yearly
                              ? PLANS.premium.prices.yearly.id
                              : PLANS.premium.prices.monthly.id
                          )
                        }
                      }}
                    >
                      {props.upgrade ? 'Actualizar' : 'Iniciar'} Plan
                    </Button>
                  )}
                </CardBody>
                <CardFooter>
                  <a className='text-light' href='#features'>
                    Ver características
                  </a>
                </CardFooter>
              </Card>
              <Card
                className='card-pricing border-0 text-center mb-4 ml-4 border-radius-sm'
                style={{ borderRadius: '6px' }}
              >
                <CardHeader className='bg-transparent'>
                  <h4 className='text-uppercase ls-1 text-primary py-3 mb-0'>
                    Plan Enterprise
                  </h4>
                </CardHeader>
                <CardBody className='px-lg-4 ml-3'>
                  <div className='display-1'>
                    <i className='ni ni-briefcase-24' />
                  </div>
                  <span className='text-muted'>Obtener presupuesto</span>
                  <ul className='list-unstyled my-4'>
                    <li className='p-0'>
                      <div className='d-flex align-items-center'>
                        <div>
                          <div className='icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white'>
                            <i className='fas fa-rocket' />
                          </div>
                        </div>
                        <div>
                          <h3 className='pl-2 pt-2'>
                            <small>App corporativa personalizada</small>
                          </h3>
                        </div>
                      </div>
                    </li>
                    <li className='p-0'>
                      <div className='d-flex align-items-center'>
                        <div>
                          <div className='icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white'>
                            <i className='fas fa-question' />
                          </div>
                        </div>
                        <div>
                          <h3 className='pl-2 pt-2'>
                            <small>
                              ¿Otra necesidad?{' '}
                              <a
                                href='https://overlapp.io/contact'
                                target='_blank'
                              >
                                Contáctanos
                              </a>
                            </small>
                          </h3>
                        </div>
                      </div>
                    </li>
                  </ul>
                </CardBody>
                <CardFooter>
                  <a href='https://overlapp.io/contact' target='_blank'>
                    Contáctanos
                  </a>
                </CardFooter>
              </Card>
            </div>
          </Col>
        </Row>
        <div className='d-flex justify-content-lg-center px-3 mt-5'>
          <div>
            <div className='icon icon-shape bg-gradient-white shadow rounded-circle text-primary'>
              <i className='ni ni-headphones text-primary' />
            </div>
          </div>
          <Col lg='6'>
            <p className='text-gray'>
              <strong>Contacte con nosotros</strong> si tiene cualquier duda con
              alguno de los planes, estaremos encantados de ayudarle:{' '}
              <a href='mailto:info@overlapp.io'>info@overlapp.io</a>
            </p>
          </Col>
        </div>
        <Row id='features' className='row-grid justify-content-center'>
          <Col lg='10'>
            <Table className=' mt-5' responsive>
              <thead>
                <tr>
                  <th className='px-0 bg-transparent' scope='col'>
                    <span className='text-primary font-weight-700'>
                      Característica
                    </span>
                  </th>
                  <th
                    className='text-center text-primary bg-transparent'
                    scope='col'
                  >
                    Plan Basic
                  </th>
                  <th
                    className='text-center text-primary bg-transparent'
                    scope='col'
                  >
                    Plan Pro
                  </th>
                  <th
                    className='text-center text-primary bg-transparent'
                    scope='col'
                  >
                    Plan Premium
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='px-0 d-flex flex-column'>
                    <b>Referencias</b>
                    <span>
                      - Cada referencia corresponde a un material con su formato
                      y color determinado.
                    </span>
                    <span>
                      - El tamaño del archivo requerido deberá ser un .jpg de
                      800x800px a 72pp.
                    </span>
                    <span>
                      -{' '}
                      <a
                        href='https://firebasestorage.googleapis.com/v0/b/overlapp-estudiocactus.appspot.com/o/plans%2FEjemplo%20Overlapp.jpg?alt=media&token=6643c5a5-bfda-4c7f-83d6-3260ce21b79c'
                        target='_blank'
                      >
                        Haz click aquí
                      </a>{' '}
                      para ver un ejemplo.
                    </span>
                  </td>
                  <td className='text-center'>25</td>
                  <td className='text-center'>50</td>
                  <td className='text-center'>100</td>
                </tr>
                <tr>
                  <td className='px-0 d-flex flex-column'>
                    <b>Recibe emails de clientes potenciales</b>
                    <span>
                      - Los datos de tu empresa siempre serán visibles para los
                      usuarios.
                    </span>
                  </td>
                  <td className='text-center'>
                    <i className='fas fa-check text-primary' />
                  </td>
                  <td className='text-center'>
                    <i className='fas fa-check text-primary' />
                  </td>
                  <td className='text-center'>
                    <i className='fas fa-check text-primary' />
                  </td>
                </tr>
                <tr>
                  <td className='px-0 d-flex flex-column'>
                    <b>Asistencia técnica</b>
                    <span
                      style={{
                        maxWidth: '600px',
                        whiteSpace: 'initial'
                      }}
                    >
                      - Un agente de Overlapp estará disponible de lunes a
                      viernes de 9:00h a 18:00h a través de nuestro chat para
                      atenderte.
                    </span>
                  </td>
                  <td className='text-center'>
                    <i className='fas fa-check text-primary' />
                  </td>
                  <td className='text-center'>
                    <i className='fas fa-check text-primary' />
                  </td>
                  <td className='text-center'>
                    <i className='fas fa-check text-primary' />
                  </td>
                </tr>
                <tr>
                  <td className='px-0 d-flex flex-column'>
                    <b>Estadísticas de tendencias</b>
                    <span>
                      - Estadísticas de colores, categorías, subcategorías y
                      formatos más usados.
                    </span>
                  </td>
                  <td className='text-center'>
                    <i className='fas fa-check text-primary' />
                  </td>
                  <td className='text-center'>
                    <i className='fas fa-check text-primary' />
                  </td>
                  <td className='text-center'>
                    <i className='fas fa-check text-primary' />
                  </td>
                </tr>
                <tr>
                  <td className='px-0 d-flex flex-column'>
                    <b>Estadísticas propias</b>
                    <span>- Estadísticas de tus referencias más usadas.</span>
                  </td>
                  <td className='text-center'>
                    <i className='fas fa-times text-danger' />
                  </td>
                  <td className='text-center'>
                    <i className='fas fa-check text-primary' />
                  </td>
                  <td className='text-center'>
                    <i className='fas fa-check text-primary' />
                  </td>
                </tr>
                <tr>
                  <td className='px-0 d-flex flex-column'>
                    <b>Interacciones con mis referencias</b>
                    <span
                      style={{
                        maxWidth: '600px',
                        whiteSpace: 'initial'
                      }}
                    >
                      - Visualizarás en todo momento los usuarios que están
                      interactuando con tus referencias, pudiendo ver en cada
                      interacción el email, profesión, país y ciudad del
                      usuario.
                    </span>
                  </td>
                  <td className='text-center'>
                    <i className='fas fa-times text-danger' />
                  </td>
                  <td className='text-center'>
                    <i className='fas fa-check text-primary' />
                  </td>
                  <td className='text-center'>
                    <i className='fas fa-check text-primary' />
                  </td>
                </tr>
                <tr>
                  <td className='px-0 d-flex flex-column'>
                    <b>Reporte mensual por email</b>
                    <span>
                      - Todas las estadísticas incluidas en tu plan serán
                      enviadas mensualmente a tu email.
                    </span>
                  </td>
                  <td className='text-center'>
                    <i className='fas fa-times text-danger' />
                  </td>
                  <td className='text-center'>
                    <i className='fas fa-check text-primary' />
                  </td>
                  <td className='text-center'>
                    <i className='fas fa-check text-primary' />
                  </td>
                </tr>
                <tr>
                  <td className='px-0 d-flex flex-column'>
                    <b>Publicación RRSS Overlapp</b>
                    <span>
                      - Publicación promocional mensual de la empresa en las
                      redes sociales de Overlapp.
                    </span>
                  </td>
                  <td className='text-center'>
                    <i className='fas fa-times text-danger' />
                  </td>
                  <td className='text-center'>
                    <i className='fas fa-times text-danger' />
                  </td>
                  <td className='text-center'>
                    <i className='fas fa-check text-primary' />
                  </td>
                </tr>
                <tr>
                  <td className='px-0 d-flex flex-column'>
                    <b>Estadísticas avanzadas</b>
                    <span>
                      - Estadísticas de las referencias más usadas en Overlapp.
                    </span>
                  </td>
                  <td className='text-center'>
                    <i className='fas fa-times text-danger' />
                  </td>
                  <td className='text-center'>
                    <i className='fas fa-times text-danger' />
                  </td>
                  <td className='text-center'>
                    <i className='fas fa-check text-primary' />
                  </td>
                </tr>
                <tr>
                  <td className='px-0 d-flex flex-column'>
                    <b>Notificaciones push segmentadas</b>
                    <span
                      style={{
                        maxWidth: '600px',
                        whiteSpace: 'initial'
                      }}
                    >
                      - Envía hasta 2 notificaciones push a todos los usuarios
                      de overlapp, pudiendo segmentar la notificación por
                      profesión del usuario.
                    </span>
                  </td>
                  <td className='text-center'>
                    <i className='fas fa-times text-danger' />
                  </td>
                  <td className='text-center'>
                    <i className='fas fa-times text-danger' />
                  </td>
                  <td className='text-center'>2/mes</td>
                </tr>
                <tr>
                  <td className='px-0 d-flex flex-column'>
                    <b>Posicionamiento BASIC</b>
                    <span>
                      - Tus referencias aparecerán siempre por debajo de los
                      planes PRO y PREMIUM.
                    </span>
                  </td>
                  <td className='text-center'>
                    <i className='fas fa-check text-primary' />
                  </td>
                  <td className='text-center'>-</td>
                  <td className='text-center'>-</td>
                </tr>
                <tr>
                  <td className='px-0 d-flex flex-column'>
                    <b>Posicionamiento PRO</b>
                    <span>
                      - Tus referencias aparecerán siempre por debajo de las del
                      plan PREMIUM.
                    </span>
                  </td>
                  <td className='text-center'>-</td>
                  <td className='text-center'>
                    <i className='fas fa-check text-primary' />
                  </td>
                  <td className='text-center'>-</td>
                </tr>
                <tr>
                  <td className='px-0 d-flex flex-column'>
                    <b>Posicionamiento PREMIUM</b>
                    <span>
                      - Tus referencias siempre aparecerán en el top de
                      Overlapp.
                    </span>
                  </td>
                  <td className='text-center'>-</td>
                  <td className='text-center'>-</td>
                  <td className='text-center'>
                    <i className='fas fa-check text-primary' />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Pricing
