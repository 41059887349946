import React, { useState } from 'react'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup
} from 'reactstrap'
import ReactPlayer from 'react-player'

const VIDEOS = [
  {
    title: 'Panel de administración',
    video: 'https://youtu.be/L2XuRXWALZo'
  },
  {
    title: 'Referencias',
    video: 'https://youtu.be/v0W3EovCAMI'
  },
  {
    title: 'Notificaciones',
    video: 'https://youtu.be/L2XuRXWALZo'
  },
  {
    title: 'Mi perfil',
    video: 'https://youtu.be/NvmafU7n_K4'
  }
]

export const Tutorial = props => {
  const [notShow, setNotShow] = useState(false)
  const [videoIndex, setVideoIndex] = useState(0)
  return (
    <Modal size='lg' isOpen={props.modal}>
      <ModalHeader>
        <span className='text-primary font-weight-bold'>{VIDEOS[videoIndex].title}</span> | Tutorial primeros pasos
      </ModalHeader>
      <ModalBody className='text-center'>
        <div style={{
          position: 'relative',
          paddingTop: '56.25%'
        }}
        >
          <ReactPlayer
            style={{
              position: 'absolute',
              top: 0,
              left: 0
            }}
            url={VIDEOS[videoIndex].video}
            width='100%'
            height='100%'
          />
        </div>
      </ModalBody>
      <ModalFooter style={{
        justifyContent: 'space-between'
      }}
      >
        <FormGroup className='mx-2' check>
          <Label check>
            <Input
              type='checkbox'
              checked={notShow}
              onChange={() => setNotShow(!notShow)}
            />
            <span className='text-light'>No volver a mostrar</span>
          </Label>
        </FormGroup>
        <Button
          color='primary'
          onClick={() => {
            if (videoIndex === (VIDEOS.length - 1)) {
              props.toggle(notShow)
            } else {
              setVideoIndex(videoIndex + 1)
            }
          }}
        >
          {videoIndex === (VIDEOS.length - 1) ? 'Finalizar' : 'Siguiente >'}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
