import React from 'react'
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  ListGroupItem,
  ListGroup,
  Row,
  Col
} from 'reactstrap'

export const BasicStatCard = props => {
  // const numberWithCommas = x => {
  //   var parts = x.toString().split('.')
  //   parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  //   return parts.join('.')
  // }

  return (
    <Card style={{ height: '100%' }} className='bg-gradient-secondary'>
      <CardHeader className='bg-transparent'>
        <div className='col'>
          <h6 className='text-primary text-uppercase ls-1 mb-1'>
            Estadísticas de tendencias
          </h6>
          <h5 className='text h3 mb-0'>{props.title}</h5>
        </div>
      </CardHeader>
      <CardBody>
        <ListGroup className='bg-transparent list my--3' flush>
          {props?.stats?.slice(0, 5).map((stat, index) => {
            return (
              <ListGroupItem style={{ borderBottom: '1px solid #e9ecef21' }} key={stat.name} className='bg-transparent px-0 p-1'>
                <Row className='align-items-center'>
                  <Col className='p-0 pl-2' xl='3'>
                    <h5 className='text-primary'>
                      <b>{index + 1}</b>
                      {/* <b>{numberWithCommas(stat.value)}</b> */}
                    </h5>
                  </Col>
                  <Col className='p-0' xl='9'>
                    {props.statsColor ? (
                      <div
                        style={{
                          background: stat.name,
                          width: '100%',
                          height: '24px',
                          borderRadius: '24px',
                          boxShadow: '0 2px 4px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)'
                        }}
                      />
                    ) : props.statsSubKey ? (
                      <h5 className=''>
                        <b>{props?.statsKey[stat.name.split('-')[0]]?.name}</b>{' '}>{' '}
                        {props?.statsSubKey[stat.name.split('-')[0]][stat.name.split('-')[1]]?.name}
                      </h5>
                    ) : (
                      <h5 className=''>
                        {props?.statsKey[stat.name].name}
                      </h5>
                    )}
                  </Col>
                </Row>
              </ListGroupItem>
            )
          })}
        </ListGroup>
      </CardBody>
    </Card>
  )
}
