import React, { useEffect, useState, useContext } from 'react'
// Context
import UserContext from 'context/UserContext'
// // Firebase
import firebase from 'auth/Auth'
// Select
import Select from 'react-select'
// Dropzone
import Dropzone from 'dropzone'
// reactstrap components
import {
  Button,
  Modal,
  Container,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap'
// Config
import { FILTERS } from 'config/filters'
// Formik
import { Formik } from 'formik'
import * as Yup from 'yup'
// Underscore
import _ from 'underscore'

Dropzone.autoDiscover = false

const customStyles = {
  control: base => ({
    ...base,
    borderColor: '#DEE2E6',
    minHeight: 46,
    '&:hover': { borderColor: '#DEE2E6' },
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    border: '1px solid #DEE2E6',
    boxShadow: 'none'
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      fontSize: '0.875rem',
      color: '#8898aa'
    }
  }
}

const styles = {
  boxLabel: {
    minWidth: 180
  }
}

export const ModalMaterial = props => {
  const context = useContext(UserContext)
  const [imagePreview, setImagePreview] = useState(null)
  const [imageError, setImageError] = useState(true)
  const [replaceImage, setReplaceImage] = useState(false)

  useEffect(() => {
    if (props.showModalMaterial) {
      if (props.initialValues) {
        setImagePreview(null)
        setImageError(false)
        setReplaceImage(false)
      } else {
        setImagePreview(null)
        setImageError(true)
        setTimeout(() => {
          initDropzone()
        }, 100)
      }
    }
  }, [props.showModalMaterial])

  const initDropzone = () => {
    // eslint-disable-next-line
    new Dropzone(document.getElementById('dropzone-single'), {
      url: '/',
      thumbnailWidth: null,
      thumbnailHeight: null,
      dictDefaultMessage: 'Imagen de la referencia',
      previewsContainer: document.getElementsByClassName(
        'dz-preview-single'
      )[0],
      previewTemplate: document.getElementsByClassName('dz-preview-single')[0]?.innerHTML,
      maxFiles: 1,
      acceptedFiles: 'image/*',
      init: function () {
        this.on('addedfile', function (file) {
          if (imagePreview) {
            this.removeFile(imagePreview)
          }
          setImagePreview(file)
        })
        this.on('thumbnail', function (file) {
          if (file.width !== 800 || file.height !== 800) {
            setImageError(true)
          } else {
            setImageError(false)
          }
        })
      }
    })
    document.getElementsByClassName('dz-preview-single')[0].innerHTML = ''
  }

  useEffect(() => {
    if (replaceImage) {
      initDropzone()
    }
  }, [replaceImage])

  const getInitialValues = () => {
    if (props.initialValues) {
      return {
        ...props.initialValues,
        use: props.initialValues.use.map(item => {
          return {
            label: FILTERS.use[item].name,
            value: item
          }
        })
      }
    } else {
      return {
        color: [],
        use: []
      }
    }
  }

  return (
    <Modal
      size='xl'
      className='modal-dialog-centered'
      isOpen={props.showModalMaterial}
      toggle={() => props.toggleModal()}
    >
      <Formik
        validateOnMount
        initialValues={getInitialValues()}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true)
          const ref = firebase.firestore()
            .collection('materials')
            .doc()

          let urlImage = null
          if (imagePreview) {
            const uploadLayer = await firebase.storage()
              .ref()
              .child(`materials/${ref.id}.jpg`)
              .put(imagePreview)

            urlImage = await uploadLayer.ref.getDownloadURL()
          }

          const refMaterial = firebase.firestore()
            .collection('materials')
            .doc(props?.initialValues?.materialId || ref.id)

          await refMaterial
            .set({
              ...values,
              company: context.company.id,
              use: values.use.map(useObject => useObject.value),
              ...(urlImage && { image: urlImage })
            })

          setSubmitting(false)
          props.toggleModal()
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .required(),
          collection: Yup.string()
            .required(),
          description: Yup.string(),
          width: Yup.string(),
          height: Yup.string(),
          category: Yup.string()
            .required(),
          subcategory: Yup.mixed(),
          format: Yup.string()
            .required(),
          use: Yup.array()
            .required(),
          color: Yup.array()
            .required()
        })}
      >
        {formikProps => {
          const {
            values,
            isValid,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            dirty
          } = formikProps
          return (
            <form onSubmit={handleSubmit}>
              <div className='modal-header'>
                <h5 className='modal-title'>
                  Nueva Referencia
                </h5>
                <button
                  aria-label='Close'
                  className='close'
                  data-dismiss='modal'
                  type='button'
                  onClick={() => props.toggleModal()}
                >
                  <span aria-hidden>×</span>
                </button>
              </div>
              <div className='modal-body'>
                <Container>
                  <Row>
                    <Col md='12' lg='4' xl='4'>
                      {props?.initialValues?.image && !replaceImage ? (
                        <div style={{ textAlign: 'center' }}>
                          <img width='100%' alt='referencia' src={props.initialValues.image} />
                          <Button
                            size='sm'
                            onClick={() => {
                              setImagePreview()
                              setReplaceImage(true)
                            }}
                            className='mt-4'
                            color='primary'
                            outline
                            type='button'
                          >
                            Reemplazar imagen
                          </Button>
                        </div>
                      ) : (
                        <>
                          <div style={{ width: 200, height: 200 }} className='dropzone dropzone-single mb-3' id='dropzone-single'>
                            <div className='fallback'>
                              <div className='custom-file'>
                                <input
                                  className='custom-file-input'
                                  id='projectCoverUploads'
                                  type='file'
                                />
                                <label
                                  className='custom-file-label'
                                  htmlFor='projectCoverUploads'
                                >
                                    Elegir archivo
                                </label>
                              </div>
                            </div>
                            <div style={{ width: 200, height: 200 }} className='dz-preview dz-preview-single'>
                              <div className='dz-preview-cover'>
                                <img
                                  alt='...'
                                  className='dz-preview-img'
                                  data-dz-thumbnail=''
                                />
                              </div>
                            </div>
                          </div>
                          <br />
                          <h5><b>Formato:</b> JPG</h5>
                          <h5><b>Tamaño:</b> 800x800 píxeles</h5>
                          <h5><b>Resolución:</b> 72 ppp</h5>
                          {imageError && imagePreview && (
                            <h6 className='text-danger'><b>Error:</b> Formato de la imagen o tamaño incorrectos</h6>
                          )}
                        </>
                      )}

                    </Col>
                    <Col md='12' lg='8' xl='8'>
                      <InputGroup>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText style={styles.boxLabel}>Nombre</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name='name'
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder='Nombre de la referencia...'
                          type='text'
                        />
                      </InputGroup>
                      <InputGroup className='mt-4'>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText style={styles.boxLabel}>Colección</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name='collection'
                          value={values.collection}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder='Nombre de la colección...'
                          type='text'
                        />
                      </InputGroup>
                      <InputGroup className='mt-4'>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText style={styles.boxLabel}>Descripción</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name='description'
                          value={values.description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder='Descripción...'
                          type='textarea'
                        />
                      </InputGroup>
                      <div className='d-flex'>
                        <InputGroup className='mt-4 mr-4'>
                          <InputGroupAddon addonType='prepend'>
                            <InputGroupText style={styles.boxLabel}>Ancho</InputGroupText>
                          </InputGroupAddon>
                          <Input
                            name='width'
                            value={values.width}
                            onChange={e => {
                              setFieldValue('width', e.target.value.replace(',', '.').toUpperCase())
                            }}
                            onBlur={handleBlur}
                            placeholder='Ancho...'
                            type='text'
                          />
                          <InputGroupAddon addonType='append'>cm</InputGroupAddon>
                        </InputGroup>
                        <InputGroup className='mt-4 ml-4'>
                          <InputGroupAddon addonType='prepend'>
                            <InputGroupText style={styles.boxLabel}>Alto</InputGroupText>
                          </InputGroupAddon>
                          <Input
                            name='height'
                            value={values.height}
                            onChange={e => {
                              setFieldValue('height', e.target.value.replace(',', '.').toUpperCase())
                            }}
                            onBlur={handleBlur}
                            placeholder='Alto...'
                            type='text'
                          />
                          <InputGroupAddon addonType='append'>cm</InputGroupAddon>
                        </InputGroup>
                      </div>
                      <InputGroup className='mt-4'>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText style={styles.boxLabel}>Categoría</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name='category'
                          value={values.category}
                          onChange={e => {
                            setFieldValue('category', e.target.value)
                            if (!FILTERS.subcategory[e.target.value]) {
                              setFieldValue('subcategory', null)
                            }
                          }}
                          onBlur={handleBlur}
                          type='select'
                        >
                          <option hidden selected>Selecciona una opción</option>
                          {Object.keys(FILTERS.category).map(filter => {
                            return <option key={filter} value={filter}>{FILTERS.category[filter].name}</option>
                          })}
                        </Input>
                      </InputGroup>
                      <InputGroup className='mt-4'>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText style={styles.boxLabel}>Subcategoría</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name='subcategory'
                          value={values.subcategory}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type='select'
                        >
                          <option hidden selected>Selecciona una opción</option>
                          {FILTERS.subcategory[values.category] && Object.keys(FILTERS.subcategory[values.category]).map(filter => {
                            return <option key={filter} value={filter}>{FILTERS.subcategory[values.category][filter]?.name}</option>
                          })}
                        </Input>
                      </InputGroup>
                      <InputGroup className='mt-4'>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText style={styles.boxLabel}>Formato</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name='format'
                          value={values.format}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type='select'
                        >
                          <option hidden selected>Selecciona una opción</option>
                          {Object.keys(FILTERS.format).map(format => {
                            return <option key={format} value={format}>{FILTERS.format[format].name}</option>
                          })}
                        </Input>
                      </InputGroup>
                      <InputGroup className='mt-4'>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText style={styles.boxLabel}>Uso/s</InputGroupText>
                        </InputGroupAddon>
                        <div style={{ flex: 1 }}>
                          <Select
                            placeholder='Selecciona uso/s'
                            styles={customStyles}
                            isMulti
                            value={values.use}
                            onChange={value => setFieldValue('use', value)}
                            name='colors'
                            options={Object.keys(FILTERS.use).map(use => {
                              return {
                                value: use,
                                label: FILTERS.use[use].name
                              }
                            })}
                            theme={theme => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: '#D0EAA3',
                                primary50: '##D0EAA3',
                                primary: '#585858'
                              }
                            })}
                            className='basic-multi-select'
                            classNamePrefix='select'
                          />
                        </div>
                      </InputGroup>
                      <InputGroup style={{ display: 'flex', alignItems: 'center' }} className='mt-4'>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText style={styles.boxLabel}>Tono/s</InputGroupText>
                        </InputGroupAddon>
                        <div className='colorsBox'>
                          <div
                            onClick={() => {
                              if (values.color.includes('multicolor')) {
                                setFieldValue('color', _.without(values.color, 'multicolor'))
                              } else {
                                setFieldValue('color', [...values.color, 'multicolor'])
                              }
                            }}
                            className={values.color.includes('multicolor') ? 'active' : ''}
                            style={{
                              backgroundImage: 'url(' + require('assets/img/rainbox.png') + ')'
                            }}
                          />
                          {FILTERS.color.map(color => {
                            return (
                              <div
                                onClick={() => {
                                  if (values.color.includes(color)) {
                                    setFieldValue('color', _.without(values.color, color))
                                  } else {
                                    setFieldValue('color', [...values.color, color])
                                  }
                                }}
                                className={values.color.includes(color) ? 'active' : ''}
                                key={color}
                                style={{
                                  backgroundColor: color
                                }}
                              />
                            )
                          })}
                        </div>
                      </InputGroup>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className='modal-footer'>
                <Button
                  disabled={
                    !isValid ||
                    imageError ||
                    isSubmitting ||
                    (!dirty && !imagePreview)
                  }
                  type='submit'
                  color='primary'
                >
                  {isSubmitting ? (
                    'Cargando...'
                  ) : props.initialValues ? 'Actualizar referencia' : 'Crear referencia'}
                </Button>
              </div>

            </form>
          )
        }}
      </Formik>
    </Modal>
  )
}
