export const FILTERS = {
  category: {
    ceramic: {
      name: 'Cerámica'
    },
    naturalStone: {
      name: 'Piedra Natural'
    },
    wood: {
      name: 'Madera'
    },
    wallPaper: {
      name: 'Papel Pintado'
    },
    paint: {
      name: 'Pintura'
    },
    microCement: {
      name: 'Micro Cemento'
    },
    decorativePanel: {
      name: 'Panel Decorativo'
    },
    artificialGrass: {
      name: 'Césped Artificial'
    },
    verticalGarden: {
      name: 'Jardín Vertical'
    }
  },
  subcategory: {
    wallPaper: {
      botanic: {
        name: 'Botánico'
      },
      geometric: {
        name: 'Geométrico'
      },
      materials: {
        name: 'Materiales'
      },
      infantile: {
        name: 'Infantil'
      },
      stripes: {
        name: 'Rayas'
      }
    },
    ceramic: {
      wood: {
        name: 'Madera'
      },
      cement: {
        name: 'Cemento'
      },
      marble: {
        name: 'Mármol'
      },
      stone: {
        name: 'Piedra'
      },
      mosaic: {
        name: 'Mosaico'
      },
      hydraulic: {
        name: 'Hidráulico'
      },
      metal: {
        name: 'Metal'
      },
      textil: {
        name: 'Textil'
      },
      flat: {
        name: 'Material Liso'
      },
      other: {
        name: 'Otro'
      }
    },
    naturalStone: {
      marble: {
        name: 'Mármol'
      },
      granite: {
        name: 'Granito'
      },
      quartz: {
        name: 'Cuarcita'
      },
      onyx: {
        name: 'Onyx'
      }
    }
  },
  format: {
    smallFormat: {
      name: 'Pequeño Formato'
    },
    mediumFormat: {
      name: 'Medio Formato'
    },
    bigFormat: {
      name: 'Gran Formato'
    }
  },
  use: {
    floor: {
      name: 'Pavimento'
    },
    wall: {
      name: 'Revestimiento'
    },
    countertop: {
      name: 'Encimera'
    },
    facade: {
      name: 'Fachada'
    },
    edge: {
      name: 'Canto'
    }
  },
  color: ['#EFEFEF', '#999999', '#222222', '#BF8940', '#C80000', '#FF9800', '#FCCB00', '#008B02', '#004DCF', '#9C27B0']
}
