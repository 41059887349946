import React from 'react'
// Components
import Pricing from 'components/Pricing/Pricing'
// reactstrap components
import {
  Container
} from 'reactstrap'

export default function UpgradePlan () {
  return (
    <>
      <Container className='mt--6' fluid>
        <Pricing upgrade />
      </Container>
    </>
  )
}
