import React, { useState, useEffect } from 'react'
// // Firebase
import firebase from 'auth/Auth'
// react plugin used to create charts
import { Pie } from 'react-chartjs-2'
// Config
import { PROFESSIONS } from 'config/professions'
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  ListGroupItem,
  ListGroup,
  Table,
  CardTitle
} from 'reactstrap'
// Moment
// moment
import moment from 'moment'
// react plugin for creating vector maps
import { VectorMap } from 'react-jvectormap'
// underscore
import _ from 'underscore'
// Date fns
import {
  getYear,
  getMonth,
  getDate,
  getWeekOfMonth,
  fromUnixTime
} from 'date-fns'
import CardFooter from 'reactstrap/lib/CardFooter'
// Lookup country
const lookup = require('country-code-lookup')

const Stats = () => {
  const [users, setUsers] = useState([])
  const [references, setReferences] = useState([])
  const [companies, setCompanies] = useState([])
  const [usages, setUsages] = useState([])
  const [revenue, setRevenue] = useState(0)
  const [userFilter, setUserFilter] = useState('all')

  useEffect(() => {
    const stripePayouts = firebase.functions().httpsCallable('stripePayouts')
    stripePayouts().then(result => {
      const numberWithCommas = x => {
        var parts = x.toString().split('.')
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
        return parts.join('.')
      }
      setRevenue(numberWithCommas((result.data).toString().replace('.', ',')))
    })

    firebase.firestore()
      .collection('appUsers')
      .orderBy('createdAt', 'desc')
      .get()
      .then(snapshot => {
        if (snapshot.docs.length > 0) {
          setUsers(snapshot.docs.map(user => {
            return {
              ...user.data(),
              id: user.id
            }
          }))
        } else {
          setUsers([])
        }
      })

    firebase.firestore()
      .collection('materials')
      .get()
      .then(snapshot => {
        if (snapshot.docs.length > 0) {
          setReferences(snapshot.docs.map(reference => {
            return {
              ...reference.data(),
              id: reference.id
            }
          }))
        } else {
          setReferences([])
        }
      })

    firebase.firestore()
      .collection('companies')
      .get()
      .then(snapshot => {
        if (snapshot.docs.length > 0) {
          setCompanies(snapshot.docs.map(company => {
            return {
              ...company.data(),
              id: company.id
            }
          }))
        } else {
          setCompanies([])
        }
      })

    firebase.firestore()
      .collection('actions')
      .where('action', '==', 'usage')
      .get()
      .then(snapshot => {
        if (snapshot.docs.length > 0) {
          setUsages(snapshot.docs.map(action => {
            return {
              ...action.data(),
              id: action.id
            }
          }))
        } else {
          setUsages([])
        }
      })
  }, [])

  const filterUsers = () => {
    return users.filter(user => {
      const today = new Date()
      const currentYear = getYear(today)
      const currentMonth = getMonth(today)
      const currentDay = getDate(today)
      const currentWeek = getWeekOfMonth(today, { locale: 'ES' })
      const userYear = getYear(fromUnixTime(user.createdAt))
      const userMonth = getMonth(fromUnixTime(user.createdAt))
      const userDay = getDate(fromUnixTime(user.createdAt))
      const userWeek = getWeekOfMonth(fromUnixTime(user.createdAt), { locale: 'ES' })
      if (userFilter === 'all') {
        return true
      } else if (userFilter === 'month') {
        if (currentYear === userYear && currentMonth === userMonth) {
          return true
        } else {
          return false
        }
      } else if (userFilter === 'week') {
        if (currentYear === userYear && currentMonth === userMonth && currentWeek === userWeek) {
          return true
        } else {
          return false
        }
      } else if (userFilter === 'today') {
        if (currentYear === userYear && currentMonth === userMonth && currentDay === userDay) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    })
  }

  const getMapData = () => {
    const result = {}
    users.forEach(user => {
      const country = lookup.byCountry(user.country)
      result[country.iso2] = result[country.iso2] + 1 || 0
    })

    return result
  }
  return (
    <>
      <div className='header bg-primary pt-4 pb-6'>
        <Container fluid>
          <div className='header-body'>
            <Row className='justify-content-md-between'>
              <Col md='6' xl>
                <Card className='card-stats'>
                  <CardBody>
                    <Row>
                      <div className='col'>
                        <CardTitle
                          tag='h5'
                          className='text-uppercase text-muted mb-0'
                        >
                          Usuarios
                        </CardTitle>
                        <span className='h1 font-weight-bold mb-0'>
                          {users.length}
                        </span>
                      </div>
                      <Col className='col-auto'>
                        <div className='icon icon-shape bg-primary text-white rounded-circle shadow'>
                          <i className='ni ni-circle-08' />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md='6' xl>
                <Card className='card-stats'>
                  <CardBody>
                    <Row>
                      <div className='col'>
                        <CardTitle
                          tag='h5'
                          className='text-uppercase text-muted mb-0'
                        >
                          Referencias
                        </CardTitle>
                        <span className='h1 font-weight-bold mb-0'>
                          {references.length}
                        </span>
                      </div>
                      <Col className='col-auto'>
                        <div className='icon icon-shape bg-primary text-white rounded-circle shadow'>
                          <i className='ni ni-app' />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md='6' xl>
                <Card className='card-stats'>
                  <CardBody>
                    <Row>
                      <div className='col'>
                        <CardTitle
                          tag='h5'
                          className='text-uppercase text-muted mb-0'
                        >
                          Empresas
                        </CardTitle>
                        <span className='h1 font-weight-bold mb-0'>
                          {companies.length}
                        </span>
                      </div>
                      <Col className='col-auto'>
                        <div className='icon icon-shape bg-primary text-white rounded-circle shadow'>
                          <i className='ni ni-briefcase-24' />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md='6' xl>
                <Card className='card-stats'>
                  <CardBody>
                    <Row>
                      <div className='col'>
                        <CardTitle
                          tag='h5'
                          className='text-uppercase text-muted mb-0'
                        >
                          Usos
                        </CardTitle>
                        <span className='h1 font-weight-bold mb-0'>
                          {usages.length}
                        </span>
                      </div>
                      <Col className='col-auto'>
                        <div className='icon icon-shape bg-primary text-white rounded-circle shadow'>
                          <i className='ni ni-active-40' />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md='12' xl>
                <Card className='card-stats'>
                  <CardBody>
                    <Row>
                      <div className='col'>
                        <CardTitle
                          tag='h5'
                          className='text-uppercase text-muted mb-0'
                        >
                          Facturación
                        </CardTitle>
                        <span className='h1 font-weight-bold mb-0'>
                          {revenue}€
                        </span>
                      </div>
                      <Col className='col-auto'>
                        <div className='icon icon-shape bg-primary text-white rounded-circle shadow'>
                          <i className='ni ni-money-coins' />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Container className='mt--6' fluid>
        <Row>
          <Col xl='7'>
            <Card style={{ height: '100%' }} className='widget-calendar'>
              <CardHeader>
                <Row className='align-items-center'>
                  <Col xs='8'>
                    <h6 className='surtitle'>Paises</h6>
                    <h5 className='h3 mb-0'>Paises de los usuarios</h5>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <VectorMap
                  map='world_mill'
                  backgroundColor='transparent'
                  zoomOnScroll={false}
                  containerStyle={{
                    width: '100%',
                    height: '280px',
                    background: '#FFFFF'
                  }}
                  containerClassName='vector-map vector-map-sm'
                  regionStyle={{
                    initial: {
                      fill: '#e4e4e4',
                      'fill-opacity': 0.9,
                      stroke: 'none',
                      'stroke-width': 0,
                      'stroke-opacity': 0
                    }
                  }}
                  series={{
                    regions: [
                      {
                        values: getMapData(),
                        scale: ['#D5E7B1', '#B4D279'],
                        normalizeFunction: 'polynomial'
                      }
                    ]
                  }}

                  onRegionTipShow={(e, el, code) => {
                    el.html(el.html() + ': ' + users.filter(user => user.country === lookup.byIso(code).country).length)
                  }}
                />

                <ListGroup className='list my--3' style={{ height: '280px', overflow: 'auto' }} flush>
                  {Object.keys(_.groupBy(users, 'country')).map(country => {
                    return (
                      <ListGroupItem key={country} className='px-0'>
                        <Row className='align-items-center'>
                          <Col className='col-auto'>
                            <img
                              alt='...'
                              src={`https://www.countryflags.io/${lookup.byCountry(country).iso2}/flat/24.png`}
                            />
                          </Col>
                          <div className='col p-0'>
                            <small>País</small>
                            <h5 className='mb-0'> {country}</h5>
                          </div>
                          <div className='col p-0'>
                            <small
                              style={{
                                color: PROFESSIONS.architect.color
                              }}
                            >Arquitectos
                            </small>
                            <h5 className='mb-0'>
                              {users.filter(user => user.country === country && user.profession === 'architect').length}
                            </h5>
                          </div>
                          <div className='col p-0'>
                            <small
                              style={{
                                color: PROFESSIONS.decorator.color
                              }}
                            >Decoradores
                            </small>
                            <h5 className='mb-0'>
                              {users.filter(user => user.country === country && user.profession === 'decorator').length}
                            </h5>
                          </div>
                          <div className='col p-0'>
                            <small
                              style={{
                                color: PROFESSIONS.reformist.color
                              }}
                            >Reformistas
                            </small>
                            <h5 className='mb-0'>
                              {users.filter(user => user.country === country && user.profession === 'reformist').length}
                            </h5>
                          </div>
                          <div className='col p-0'>
                            <small
                              style={{
                                color: PROFESSIONS.interiorist.color
                              }}
                            >Interioristas
                            </small>
                            <h5 className='mb-0'>
                              {users.filter(user => user.country === country && user.profession === 'interiorist').length}
                            </h5>
                          </div>
                          <div className='col p-0'>
                            <small
                              style={{
                                color: PROFESSIONS.particular.color
                              }}
                            >Particulares
                            </small>
                            <h5 className='mb-0'>
                              {users.filter(user => user.country === country && user.profession === 'particular').length}
                            </h5>
                          </div>
                        </Row>
                      </ListGroupItem>
                    )
                  })}
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
          {/* <Col xl='5'>
            <Card style={{ height: '100%' }}>
              <CardHeader>
                <h6 className='surtitle'>Profesión</h6>
                <h5 className='h3 mb-0'>Últimos 6 meses</h5>
              </CardHeader>
              <CardBody>
                <div className='chart'>
                  <Bar
                    options={{
                      defaultColor: '#8898aa',
                      defaultFontColor: '#8898aa',
                      defaultFontFamily: 'Nunito',
                      defaultFontSize: 13,
                      layout: {
                        padding: 0
                      },
                      elements: {
                        point: {
                          radius: 0,
                          backgroundColor: '#68EDCB'
                        },
                        line: {
                          tension: 0.4,
                          borderWidth: 4,
                          borderColor: '#68EDCB',
                          backgroundColor: 'transparent',
                          borderCapStyle: 'rounded'
                        },
                        rectangle: {
                          backgroundColor: '#FFD17E'
                        },
                        arc: {
                          backgroundColor: '#68EDCB',
                          borderColor: 'white',
                          borderWidth: 4
                        }
                      },
                      responsive: true,
                      legend: false,
                      animation: {
                        animateScale: true,
                        animateRotate: true
                      },
                      maintainAspectRatio: false,
                      tooltips: {
                        mode: 'index',
                        intersect: false
                      },
                      scales: {
                        xAxes: [
                          {
                            stacked: true
                          }
                        ],
                        yAxes: [
                          {
                            stacked: true
                          }
                        ]
                      }
                    }}
                    data={chartExample7.data}

                    className='chart-canvas'
                    id='chart-bar-stacked'
                  />
                </div>
              </CardBody>
            </Card>
          </Col> */}
          <Col xl='5'>
            <Card style={{ height: '100%' }}>
              <CardHeader>
                <h6 className='surtitle'>Profesión</h6>
                <h5 className='h3 mb-0'>Usuarios totales</h5>
              </CardHeader>
              <CardBody>
                <div className='d-flex flex-row justify-content-between'>
                  <div className='d-flex flex-column align-items-center'>
                    <i className='fas fa-circle text-architect' />
                    <b className='text-xs text-architect'> {' '}Arquitectos</b>
                    <h5>{((users.filter(user => user.profession === 'architect').length / users.length) * 100).toFixed(2)} %</h5>
                  </div>
                  <div className='d-flex flex-column align-items-center'>
                    <i className='fas fa-circle text-decorator' />
                    <b className='text-xs text-decorator'> {' '}Decoradores</b>
                    <h5>{((users.filter(user => user.profession === 'decorator').length / users.length) * 100).toFixed(2)} %</h5>
                  </div>
                  <div className='d-flex flex-column align-items-center'>
                    <i className='fas fa-circle text-reformist' />
                    <b className='text-xs text-reformist'> {' '}Reformistas</b>
                    <h5>{((users.filter(user => user.profession === 'reformist').length / users.length) * 100).toFixed(2)} %</h5>
                  </div>
                  <div className='d-flex flex-column align-items-center'>
                    <i className='fas fa-circle text-interiorist' />
                    <b className='text-xs text-interiorist'> {' '}Interioristas</b>
                    <h5>{((users.filter(user => user.profession === 'interiorist').length / users.length) * 100).toFixed(2)} %</h5>
                  </div>
                  <div className='d-flex flex-column align-items-center'>
                    <i className='fas fa-circle text-particular' />
                    <b className='text-xs text-particular'> {' '}Particulares</b>
                    <h5>{((users.filter(user => user.profession === 'particular').length / users.length) * 100).toFixed(2)} %</h5>
                  </div>
                </div>
                <div className='chart'>
                  <Pie
                    options={{
                      responsive: true,
                      legend: false,
                      animation: {
                        animateScale: true,
                        animateRotate: true
                      },
                      maintainAspectRatio: false
                    }}
                    data={{
                      labels: ['Arquitectos', 'Decoradores', 'Reformistas', 'Interioristas', 'Particulares'],
                      datasets: [
                        {
                          data: [
                            users.filter(user => user.profession === 'architect').length,
                            users.filter(user => user.profession === 'decorator').length,
                            users.filter(user => user.profession === 'reformist').length,
                            users.filter(user => user.profession === 'interiorist').length,
                            users.filter(user => user.profession === 'particular').length
                          ],
                          backgroundColor: [
                            PROFESSIONS.architect.color,
                            PROFESSIONS.decorator.color,
                            PROFESSIONS.reformist.color,
                            PROFESSIONS.interiorist.color,
                            PROFESSIONS.particular.color
                          ],
                          label: 'Dataset 1'
                        }
                      ]
                    }}

                    className='chart-canvas'
                    id='chart-pie'
                  />
                </div>

              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className='pt-4'>
          <Col xl='12'>
            <Card style={{ height: '100%' }}>
              <CardHeader className='border-0'>
                <Row className='align-items-center'>
                  <div className='col'>
                    <h6 className='surtitle'>Usuarios</h6>
                    <h5 className='h3 mb-0'>Nuevos usuarios</h5>
                  </div>
                  <div className='col text-right'>
                    <Button
                      color={userFilter === 'today' ? 'primary' : 'neutral'}
                      onClick={e => setUserFilter('today')}
                      size='sm'
                    >
                      Hoy
                    </Button>
                    <Button
                      color={userFilter === 'week' ? 'primary' : 'neutral'}
                      onClick={e => setUserFilter('week')}
                      size='sm'
                    >
                      Semana
                    </Button>
                    <Button
                      color={userFilter === 'month' ? 'primary' : 'neutral'}
                      onClick={e => setUserFilter('month')}
                      size='sm'
                    >
                      Mes
                    </Button>
                    <Button
                      color={userFilter === 'all' ? 'primary' : 'neutral'}
                      onClick={e => setUserFilter('all')}
                      size='sm'
                    >
                      Todos
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className='table-stats'>
                <Table
                  className='align-items-center table-flush'
                  responsive
                >
                  <thead className='thead-light'>
                    <tr>
                      <th scope='col'>Email</th>
                      <th scope='col'>Profesión</th>
                      <th scope='col'>País</th>
                      <th scope='col'>Ciudad</th>
                      <th scope='col'>Creación</th>
                      <th scope='col'>Últ. sesión</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filterUsers().map(user => {
                      return (
                        <tr key={user.id}>
                          <th className='font-weight-bold'>{user.email}</th>
                          <td
                            style={{
                              color: PROFESSIONS[user.profession].color
                            }}
                          >
                            {PROFESSIONS[user.profession].name}
                          </td>
                          <td>{user.country}</td>
                          <td>{user.city}</td>
                          <td className='text-light'>{moment.unix(user.createdAt).format('DD/MM/YYYY HH:mm')}</td>
                          <td className='text-light'> - </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </CardBody>
              <CardFooter>
                <div className='d-flex'>
                  <h4>TOTAL <b className='text-primary'>{filterUsers().length}</b></h4>
                </div>
              </CardFooter>
            </Card>
          </Col>

        </Row>
      </Container>
    </>
  )
}
export default Stats
