import React, { useContext } from 'react'
// Context
import UserContext from 'context/UserContext'
// reactstrap components
import {
  Container
} from 'reactstrap'

// core components
import CardsHeader from 'components/Headers/CardsHeader.js'
import { BasicStats } from 'components/Stats/BasicStats/BasicStats.js'
import { PersonalStats } from 'components/Stats/PersonalStats/PersonalStats.js'
import { AdvancedStats } from 'components/Stats/AdvancedStats/AdvancedStats'

const Dashboard = props => {
  const context = useContext(UserContext)
  return (
    <>
      <CardsHeader name='Dashboard' parentName='Dashboards' />
      <Container className='mt--6' fluid>
        {context.stripe.plan.stats.map(statType => {
          switch (statType) {
            case 'basic':
              return <BasicStats />
            case 'pro':
              return <PersonalStats />
            case 'premium':
              return <AdvancedStats />

            default:
              return null
          }
        })}
      </Container>
    </>
  )
}

export default Dashboard
