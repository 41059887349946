import React, { useEffect, useContext, useState } from 'react'
// // Firebase
import firebase from 'auth/Auth'
// Context
import UserContext from 'context/UserContext'
// react component for creating dynamic tables
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
// reactstrap components
import {
  Button,
  Card,
  Container,
  Row,
  UncontrolledTooltip,
  Modal
} from 'reactstrap'
// core components
import { PreviewThumb } from 'components/PreviewThumb/PreviewThumb'
import CardsHeader from 'components/Headers/CardsHeader.js'
// config
import { FILTERS } from 'config/filters'
// Material Modal
import { ModalMaterial } from 'components/ModalMaterial/ModalMaterial'

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: false,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className='dataTables_length' id='datatable-basic_length'>
      <label>
        Mostrar{' '}
        {
          <select
            name='datatable-basic_length'
            aria-controls='datatable-basic'
            className='form-control form-control-sm'
            onChange={e => onSizePerPageChange(e.target.value)}
          >
            <option value='10'>10</option>
            <option value='25'>25</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
          </select>
        }{' '}
        Referencias
      </label>
    </div>
  )
})

const { SearchBar } = Search

const Materials = props => {
  const context = useContext(UserContext)
  const [showModalMaterial, setShowModalMaterial] = useState(false)
  const [modalInitialValues, setModalInitialValues] = useState(null)
  const [materialsLimitModal, setMaterialsLimitModal] = useState(false)
  const [showModalDeleteMaterial, setShowModalDeleteMaterial] = useState(false)
  const [referenceToDelete, setReferenceToDelete] = useState()

  useEffect(() => {
    if (modalInitialValues) {
      setShowModalMaterial(true)
    }
  }, [modalInitialValues])

  const deleteReference = () => {
    console.log(referenceToDelete)
    firebase.firestore()
      .collection('materials')
      .doc(referenceToDelete)
      .delete()
      .then(() => {
        setShowModalDeleteMaterial(false)
        firebase.storage().ref().child('materials/' + referenceToDelete + '.jpg').delete()
        firebase.storage().ref().child('materials/' + referenceToDelete + '_200x200.jpg').delete()
      })
  }

  return (
    <>
      <Modal
        className='modal-dialog-centered'
        isOpen={showModalDeleteMaterial}
        toggle={() => setShowModalDeleteMaterial(false)}
      >
        <div className='modal-header'>
          <h5 className='modal-title' id='exampleModalLabel'>
            Borrar Material
          </h5>
          <button
            aria-label='Close'
            className='close'
            data-dismiss='modal'
            type='button'
            onClick={() => setShowModalDeleteMaterial(false)}
          >
            <span aria-hidden>×</span>
          </button>
        </div>
        <div className='modal-body'>
          <div className='d-flex justify-content-center flex-column align-items-center text-center'>
            <div className='icon icon-xl text-primary mb-4'>
              <i className='fas fa-exclamation-triangle' />
            </div>
            <span className='h3'>
              Estas a punto de borrar esta referencia, esta acción no se puede revertir. ¿Estás seguro?
            </span>
            <Button
              color='danger'
              type='button'
              className='mt-4'
              onClick={() => {
                deleteReference()
              }}
              size='sm'
            >
              Sí, eliminar referencia
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        className='modal-dialog-centered'
        isOpen={materialsLimitModal}
        toggle={() => setMaterialsLimitModal(false)}
      >
        <div className='modal-header'>
          <h5 className='modal-title' id='exampleModalLabel'>
            Límite alcanzado
          </h5>
          <button
            aria-label='Close'
            className='close'
            data-dismiss='modal'
            type='button'
            onClick={() => setMaterialsLimitModal(false)}
          >
            <span aria-hidden>×</span>
          </button>
        </div>
        <div className='modal-body'>
          <div className='d-flex justify-content-center flex-column align-items-center text-center'>
            <div className='icon icon-xl text-primary mb-4'>
              <i className='fas fa-exclamation-triangle' />
            </div>
            <span className='h3'>
              Lo sentimos, has alcanzado el límite de referencias que incluye tu plan.<br />
              <small className='text-muted'>
                <span className='text-primary text-underline cursor-pointer' onClick={() => props.history.push('/admin/upgrade-plan')}>Haz click aquí</span> para actualizar tu plan.
              </small>
            </span>
          </div>
        </div>
      </Modal>
      <ModalMaterial
        showModalMaterial={showModalMaterial}
        initialValues={modalInitialValues}
        toggleModal={() => {
          setModalInitialValues()
          setShowModalMaterial(false)
        }}
      />
      <CardsHeader name='Referencias' parentName='Components' />
      <Container className='mt--6' fluid>
        <Row>
          <div className='col'>
            <Card>
              <ToolkitProvider
                data={context.materials || []}
                keyField='name'
                columns={[
                  {
                    dataField: 'materialId',
                    text: 'Imagen',
                    sort: true,
                    formatter: (cell, row) => <PreviewThumb fullImage={row.image} materialId={cell} />
                  },
                  {
                    dataField: 'name',
                    text: 'Nombre',
                    style: {
                      fontWeight: 'bold'
                    },
                    sort: true
                  },
                  {
                    dataField: 'category',
                    text: 'Categoría',
                    sort: true,
                    formatter: (cell, row) => FILTERS.category[cell]?.name || '-'
                  },
                  {
                    dataField: 'subcategory',
                    text: 'Subcategoría',
                    sort: true,
                    formatter: (cell, row) => (FILTERS.subcategory[row.category] && FILTERS.subcategory[row.category][cell]?.name) || '-'
                  },
                  {
                    dataField: 'format',
                    text: 'Formato',
                    sort: true,
                    formatter: (cell, row) => FILTERS.format[cell]?.name || '-'
                  },
                  {
                    dataField: 'use',
                    text: 'Uso',
                    sort: true,
                    formatter: (cell, row) => {
                      return (
                        <div>
                          {cell.map(use => {
                            return (
                              <div
                                key={use}
                                style={{
                                  fontWeight: 'bold',
                                  margin: '6px 0',
                                  height: '24px',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  borderRadius: '2px',
                                  padding: '0 10px',
                                  backgroundColor: '#E6E6E6',
                                  boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2)'
                                }}
                              >
                                {FILTERS.use[use]?.name}
                              </div>
                            )
                          })}
                        </div>
                      )
                    }
                  },
                  {
                    dataField: 'color',
                    text: 'Color',
                    sort: true,
                    formatter: (cell, row) => {
                      return (
                        <div>
                          {cell.map(color => {
                            return (
                              <div
                                key={color}
                                style={{
                                  width: '24px',
                                  height: '24px',
                                  margin: '4px',
                                  borderRadius: '2px',
                                  backgroundColor: color,
                                  ...(color === 'multicolor' && { backgroundImage: 'url(' + require('assets/img/rainbox.png') + ')' }),
                                  backgroundSize: '24px',
                                  boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2)'
                                }}
                              />
                            )
                          })}
                        </div>
                      )
                    }
                  },
                  {
                    dataField: '',
                    text: 'Acciones',
                    formatter: (cell, row) => {
                      return (
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div
                            className='table-action'
                            style={{ padding: 10, cursor: 'pointer' }}
                            id='edit'
                            onClick={e => {
                              setModalInitialValues(row)
                            }}
                          >
                            <i className='fas fa-edit' />
                          </div>
                          <UncontrolledTooltip delay={0} target='edit'>
                            Editar referencia
                          </UncontrolledTooltip>

                          <div
                            className='table-action table-action-delete'
                            style={{ padding: 10, cursor: 'pointer' }}
                            id='delete'
                            onClick={e => {
                              setReferenceToDelete(row.materialId)
                              setShowModalDeleteMaterial(true)
                            }}
                          >
                            <i className='fas fa-trash' />
                          </div>
                          <UncontrolledTooltip delay={0} target='delete'>
                            Eliminar referencia
                          </UncontrolledTooltip>
                        </div>
                      )
                    }
                  }
                ]}
                search
              >
                {props => (
                  <div className='py-4 table-responsive'>
                    <div
                      id='datatable-basic_filter'
                      className='dataTables_filter px-4 pb-1'
                    >
                      <label>
                        <SearchBar
                          className='form-control-sm'
                          placeholder='Buscar...'
                          {...props.searchProps}
                        />
                      </label>
                      <Button
                        className='btn-icon float-right'
                        type='button'
                        color='default'
                        onClick={e => {
                          if ((context.materials.length || 0) >= context.stripe.plan.materials) {
                            setMaterialsLimitModal(true)
                          } else {
                            setShowModalMaterial(true)
                          }
                        }}
                        size='sm'
                      >
                        <i className='ni ni-fat-add' /> Nueva referencia
                      </Button>
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      sort={{ dataField: 'name', order: 'asc' }}
                      noDataIndication={
                        <div className='text-center'>
                          <span className='h3'>
                            Listado de referencias vacío.<br />
                            <small className='text-muted'>
                            Échale un vistazo al apartado de Referencias en nuestras
                              <span
                                className='text-primary cursor-pointer'
                                onClick={() => {
                                  window.fcWidget.open()
                                  window.fcWidget.show()
                                }}
                              >FAQ
                              </span> para ver cómo subir correctamente una referencia.
                            </small>
                          </span>
                        </div>
                      }
                      bootstrap4
                      pagination={pagination}
                      bordered={false}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default Materials
