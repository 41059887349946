import React from 'react'
// Components
import CustomerInfo from 'components/CustomerInfo/CustomerInfo'
// reactstrap components
import {
  Container
} from 'reactstrap'
// core components
import CardsHeader from 'components/Headers/CardsHeader.js'

const Company = props => {
  return (
    <>
      <CardsHeader name='Empresa' parentName='Components' />
      <Container className='mt--9' fluid>
        <CustomerInfo />
      </Container>
    </>
  )
}

export default Company
