import React, { useContext, useEffect, useState, useRef } from 'react'
// CSV
import { CSVLink } from 'react-csv'
// Firebase
import firebase from 'auth/Auth'
// Context
import UserContext from 'context/UserContext'
// moment
import moment from 'moment'
// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Row,
  Col,
  CardFooter,
  CardBody,
  Button
} from 'reactstrap'
// underscore
import _ from 'underscore'
// Config
import { PROFESSIONS } from 'config/professions'

export const PersonalStats = () => {
  const context = useContext(UserContext)
  const [materials, setMaterials] = useState([])
  const [actions, setActions] = useState([])
  const csvLinkUserInteractions = useRef(null)
  const csvLinkMaterials = useRef(null)
  const [csvDataMaterials, setCsvDataMaterials] = useState([])
  const [csvDataInteractions, setCsvDataInteractions] = useState([])

  const numberWithCommas = x => {
    var parts = x.toString().split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    return parts.join('.')
  }

  useEffect(() => {
    firebase.firestore()
      .collection('materials')
      .where('company', '==', context.company.id)
      .orderBy('usages', 'desc')
      .onSnapshot(async snapshot => {
        if (snapshot.docs.length > 0) {
          setMaterials(snapshot.docs.map(material => {
            return {
              ...material.data(),
              id: material.id
            }
          }))
        } else {
          setMaterials([])
        }
      })

    firebase.firestore()
      .collection('actions')
      .where('company', '==', context.company.id)
      .orderBy('createdAt', 'desc')
      .limit(100)
      .onSnapshot(snapshot => {
        if (snapshot.docs.length > 0) {
          setActions(snapshot.docs.map(action => {
            return {
              ...action.data(),
              id: action.id
            }
          }))
        }
      })
  }, [context.company.id])

  const getAllUserInteractions = () => {
    firebase.firestore()
      .collection('actions')
      .where('company', '==', context.company.id)
      .get()
      .then(async snapshot => {
        if (snapshot.docs.length > 0) {
          setCsvDataInteractions(snapshot.docs.map(action => {
            return {
              Fecha: moment.unix(action.data().createdAt).format('DD/MM/YYYY HH:mm'),
              Email: action.data().appUser.email,
              Profesion: PROFESSIONS[action.data().appUser.profession]?.name,
              Pais: action.data().appUser.country,
              Ciudad: action.data().appUser.city,
              Material: _.findWhere(materials, { id: action.data().materialId })?.name
            }
          }))
        } else {
          setCsvDataInteractions([])
        }
      })
  }

  useEffect(() => {
    if (csvDataInteractions.length > 0) {
      setTimeout(() => {
        csvLinkUserInteractions.current.link.click()
      }, 100)
    }
  }, [csvDataInteractions])

  const getAllMaterials = () => {
    firebase.firestore()
      .collection('materials')
      .where('company', '==', context.company.id)
      .orderBy('usages', 'desc')
      .get()
      .then(async snapshot => {
        if (snapshot.docs.length > 0) {
          const allMaterials = []
          for (const material of snapshot.docs) {
            if (material.data().company) {
              const companyInfo = await firebase.firestore().collection('companies').doc(material.data().company).get()
              allMaterials.push({
                materialInfo: material?.data(),
                companyInfo: companyInfo?.data(),
                id: material.id,
                usages: material.data().usages
              })
            }
          }
          setCsvDataMaterials(allMaterials.map(item => {
            return {
              Material: item.materialInfo.name,
              Empresa: item.companyInfo.name,
              Usos: item.usages
            }
          }))
        } else {
          setCsvDataMaterials([])
        }
      })
  }

  useEffect(() => {
    if (csvDataMaterials.length > 0) {
      setTimeout(() => {
        csvLinkMaterials.current.link.click()
      }, 100)
    }
  }, [csvDataMaterials])

  return (
    <Row className='mt-4'>
      <Col xl='4' md='12'>
        <Card>
          <CardHeader className='border-0'>
            <Row className='align-items-center'>
              <div className='col'>
                <h6 className='text-primary text-uppercase ls-1 mb-1'>
                  Estadísticas propias
                </h6>
                <p className='font-weight-normal mb-0'>Referencias de <b>{context.company.name}</b> más usadas</p>
              </div>
            </Row>
          </CardHeader>
          <CardBody className='fixed-height-stats p-0'>
            <Table className='align-items-center table-flush' responsive>
              <thead className='thead-light'>
                <tr>
                  <th scope='col'>Referencia</th>
                  <th scope='col'>Colección</th>
                  <th scope='col'>Usos</th>
                </tr>
              </thead>
              <tbody>
                {materials.map(material => {
                  return (
                    <tr key={material.id}>
                      <th scope='row'>{material.name}</th>
                      <td>{material.collection}</td>
                      <td className='text-primary'>
                        <b>{numberWithCommas(material.usages)}</b>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </CardBody>
          <CardFooter>
            <Button
              className='float-right text-gray'
              color='link'
              onClick={async () => {
                getAllMaterials()
              }}
            >
              <h6 className='text-gray text-uppercase ls-1 mb-1 float-right'>
                  Exportar
                <i className='fas fa-file-export ml-1' />
              </h6>
            </Button>
            <CSVLink
              separator=';'
              data={csvDataMaterials}
              filename='companyReferences.csv'
              ref={csvLinkMaterials}
              target='_blank'
            />
          </CardFooter>
        </Card>
      </Col>

      <Col xl='8' md='12'>
        <Card>
          <CardHeader className='border-0'>
            <Row className='align-items-center'>
              <div className='col'>
                <h6 className='text-primary text-uppercase ls-1 mb-1'>
                  Interacciones con tus referencias
                </h6>
                <p className='font-weight-normal mb-0'>Interacciones de usuarios con referencias de <b>{context.company.name}</b></p>
              </div>
            </Row>
          </CardHeader>
          <CardBody className='fixed-height-stats p-0'>
            <Table
              className='align-items-center table-flush'
              responsive
            >
              <thead className='thead-light'>
                <tr>
                  <th scope='col'>Fecha</th>
                  <th scope='col'>Email</th>
                  <th scope='col'>Profesión</th>
                  <th scope='col'>País</th>
                  <th scope='col'>Ciudad</th>
                  <th scope='col'>Referencia</th>
                </tr>
              </thead>
              <tbody>
                {actions.map(action => {
                  return (
                    <tr key={action.id}>
                      <th scope='row'>{moment.unix(action.createdAt).format('DD/MM/YYYY HH:mm')}</th>
                      <td>{action.appUser.email}</td>
                      <td className='text-primary'>
                        <b>{PROFESSIONS[action.appUser.profession]?.name}</b>
                      </td>
                      <td>{action.appUser.country}</td>
                      <td>{action.appUser.city}</td>
                      <td className='font-weight-bold'>
                        {_.findWhere(materials, { id: action.materialId })?.name}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </CardBody>
          <CardFooter>
            <Button
              className='float-right text-gray'
              color='link'
              onClick={async () => {
                getAllUserInteractions()
              }}
            >
              <h6 className='text-gray text-uppercase ls-1 mb-1 float-right'>
                  Exportar
                <i className='fas fa-file-export ml-1' />
              </h6>
            </Button>
            <CSVLink
              separator=';'
              data={csvDataInteractions}
              filename='interactions.csv'
              ref={csvLinkUserInteractions}
              target='_blank'
            />
          </CardFooter>
        </Card>
      </Col>

    </Row>
  )
}
