import React, { useContext, useState } from 'react'
// // Firebase
import firebase from 'auth/Auth'
// Context
import UserContext from 'context/UserContext'
// reactstrap components
import {
  Navbar,
  Nav,
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  NavbarToggler,
  Collapse,
  DropdownMenu
} from 'reactstrap'

const ConfigNavbar = props => {
  const context = useContext(UserContext)
  const [isOpen, setIsOpen] = useState(false)

  const openPortalLink = async () => {
    context.setLoading(true)
    const functionRef = firebase
      .functions('europe-west3')
      .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink')
    const { data } = await functionRef({ returnUrl: window.location.origin })
    window.location.href = data.url
  }

  const toggle = () => setIsOpen(!isOpen)

  return (
    <>
      <Navbar className='navbar-top navbar-expand navbar-dark' color='primary' light expand='md'>
        <Container fluid>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className='ml-auto' navbar>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  <i className={'fas fa-user mr-2 ' + (context.guestUser ? ' text-gray' : 'text-white')} />
                  {context.guestUser ? (
                    <>
                      <span className='nav-link-inner--text'><span className='font-weight-bolder text-xs text-gray mr-2'>INVITADO </span>{context.guestUser.email} </span>
                    </>
                  ) : (
                    <span className='nav-link-inner--text'>{context.user.email}</span>
                  )}

                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem className='noti-title' header tag='div'>
                    <h6 className='text-overflow m-0'>Ajustes</h6>
                  </DropdownItem>
                  {!context.guestUser && (
                    <DropdownItem
                      onClick={e => props.history.push('/admin/company')}
                    >
                      <i className='ni ni-briefcase-24' />
                      <span>Perfil empresa</span>
                    </DropdownItem>
                  )}
                  {!context.guestUser && (
                    <DropdownItem
                      onClick={e => openPortalLink()}
                    >
                      <i className='ni ni-spaceship' />
                      <span>Gestionar suscripción</span>
                    </DropdownItem>
                  )}
                  <DropdownItem
                    onClick={e => {
                      window.fcWidget.open()
                      window.fcWidget.show()
                    }}
                  >
                    <i className='fas fa-headset' />
                    <span>Soporte</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    onClick={() => firebase.auth().signOut()}
                  >
                    <i className='ni ni-button-power' />
                    <span>Cerrar sesión</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default ConfigNavbar
