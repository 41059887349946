import React from 'react'
// nodejs library to set properties for components
import PropTypes from 'prop-types'

class AuthHeader extends React.Component {
  render () {
    return (
      <>
        <div className='header py-3' />
      </>
    )
  }
}

AuthHeader.propTypes = {
  title: PropTypes.string,
  lead: PropTypes.string
}

export default AuthHeader
