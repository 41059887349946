import React, { useState } from 'react'
// reactstrap components
import {
  Button,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert
} from 'reactstrap'
// core components
import AuthHeader from 'components/Headers/AuthHeader.js'
// Firebase
import firebase from 'auth/Auth.js'
import { Formik } from 'formik'
// Formik
import * as Yup from 'yup'

const ForgotPassword = (props) => {
  const [errorMsg, setErrorMsg] = useState(null)
  const [successMsg, setSuccessMsg] = useState(null)

  return (
    <>
      <AuthHeader />
      {errorMsg && (
        <div style={{
          position: 'absolute',
          marginTop: '20px',
          zIndex: '999',
          width: '100%'
        }}
        >
          <Alert className='mt-5 container' color='danger' fade>
            <span className='alert-inner--icon'>
              <i className='fas fa-exclamation-triangle' />
            </span>{' '}
            <span className='alert-inner--text'>
              <strong>Error: </strong> {errorMsg}
            </span>
            <button onClick={() => setErrorMsg(null)} type='button' className='close' aria-label='Close'>
              <span className='text-white' aria-hidden='true'>×</span>
            </button>
          </Alert>
        </div>
      )}
      {successMsg && (
        <div style={{
          position: 'absolute',
          marginTop: '20px',
          zIndex: '999',
          width: '100%'
        }}
        >
          <Alert className='mt-5 container' color='primary' fade>
            <span className='alert-inner--text'>
              {successMsg}
            </span>
            <button onClick={() => setSuccessMsg(null)} type='button' className='close' aria-label='Close'>
              <span className='text-white' aria-hidden='true'>×</span>
            </button>
          </Alert>
        </div>
      )}
      <Container className='mt-8 pb-5'>
        <Row className='justify-content-center'>
          <Col lg='5' md='7'>
            <Formik
              initialValues={{ email: '' }}
              onSubmit={async (values, { setSubmitting }) => {
                firebase.auth().sendPasswordResetEmail(values.email)
                  .then(() => {
                    setSubmitting(false)
                    setSuccessMsg('Email enviado correctamente')
                  }).catch(err => {
                    setSubmitting(false)
                    console.log(err)
                    setErrorMsg(err.message)
                  })
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email()
                  .required('Email es obligatorio')
              })}
            >
              {formikProps => {
                const {
                  values,
                  dirty,
                  isValid,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit
                } = formikProps
                return (
                  <form onSubmit={handleSubmit}>
                    <div className='text-center pb-4'>
                      <span className='h1'>
                        Password olvidado
                      </span>
                      <p className='text-gray'>Introduce tu correo electrónico</p>
                    </div>
                    <FormGroup>
                      <InputGroup className='input-group-alternative'>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText>
                            <i className='ni ni-email-83' />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          id='email'
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder='Email'
                          type='email'
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className='text-center pt-4'>
                      <Button block disabled={!dirty || isSubmitting || !isValid} className='my-4' color='primary' type='submit'>
                        RECORDAR PASSWORD
                      </Button>
                    </div>
                  </form>
                )
              }}
            </Formik>
            <Row className='mt-3'>
              <Col className='text-center mt-6'>
                <span
                  className='text-light'
                >
                  <small>¿Ya tienes una cuenta?{' '}
                    <span
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                      href='signup'
                      className='text-primary'
                      onClick={() => props.history.push('/auth/login')}
                    >Iniciar sesión
                    </span>
                  </small>
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ForgotPassword
