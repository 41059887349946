import React, { useContext } from 'react'
// Router
import { Link } from 'react-router-dom'
// Context
import UserContext from 'context/UserContext'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Progress
} from 'reactstrap'
// moment
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const CardsHeader = props => {
  const context = useContext(UserContext)

  const progressBarColor = () => {
    const percentage = (context.materials.length * 100) / context.stripe.plan.materials

    if (percentage <= 60) {
      return 'primary'
    } else if (percentage <= 80) {
      return 'yellow'
    } else {
      return 'danger'
    }
  }

  return (
    <>
      <div className='header bg-primary pt-4 pb-6'>
        <Container fluid>
          <div className='header-body'>
            {/* <Row className='align-items-center py-4'>
              <Col lg='6' xs='7'>
                <h6 className='h2 text-white d-inline-block mb-0'>
                  {props.name}
                </h6>{' '}
              </Col>
            </Row> */}
            <Row>
              <Col md='12' xl='4'>
                <Card className='card-stats'>
                  <CardBody>
                    <Row>
                      <div className='col'>
                        <CardTitle
                          tag='h5'
                          className='text-uppercase text-muted mb-0'
                        >
                          Empresa
                        </CardTitle>
                        <span className='h3 font-weight-bold mb-0'>
                          {context.company.name}
                        </span>
                      </div>
                      <Col className='col-auto'>
                        {context?.company?.logo ? (
                          <div className='icon icon-shape text-white rounded-circle shadow'>
                            <img alt='logo' width='36' style={{ borderRadius: '50%' }} src={context.company.logo} />
                          </div>
                        ) : (
                          <div className='icon icon-shape bg-primary text-white rounded-circle shadow'>
                            <i className='ni ni-briefcase-24' />
                          </div>
                        )}
                      </Col>
                    </Row>
                    {!context.guestUser && (
                      <p className='mt-3 mb-0 text-sm'>
                        <Link to='/admin/company'>
                          <span>Actualizar perfil de empresa</span>
                        </Link>
                      </p>
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col md='12' xl='4'>
                <Card className='card-stats'>
                  <CardBody>
                    <Row>
                      <div className='col'>
                        <CardTitle
                          tag='h5'
                          className='text-uppercase text-muted mb-0'
                        >
                          Suscripción
                        </CardTitle>
                        <span className='h2 font-weight-bold mb-0' style={{ textTransform: 'capitalize' }}>
                          {context.stripe.plan.name}
                          {context.stripe.subscription.status === 'trialing' && (<small className='text-primary'> TRIAL</small>)}
                        </span>
                      </div>
                      <Col className='col-auto'>
                        <div className='icon icon-shape bg-primary text-white rounded-circle shadow'>
                          <i className='ni ni-money-coins' />
                        </div>
                      </Col>
                    </Row>
                    <p className='mt-3 mb-0 text-sm'>
                      Válida hasta: {' '}
                      <b>
                        {context.stripe.subscription.status === 'trialing' ? (
                          moment(context.stripe.subscription.current_period_end.toDate()).format('DD MMMM YYYY')
                        ) : (
                          moment(context.stripe.subscription.current_period_end.toDate()).format('DD MMMM YYYY')
                        )}
                      </b>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md='12' xl='4'>
                <Card className='card-stats'>
                  <CardBody>
                    <Row>
                      <div className='col'>
                        <CardTitle
                          tag='h5'
                          className='text-uppercase text-muted mb-0'
                        >
                          Referencias
                        </CardTitle>
                        <span className='h2 font-weight-bold mb-0'>
                          {context.materials.length}/
                          {context.stripe.plan.materials}
                        </span>
                      </div>
                      <Col className='col-auto'>
                        <div className='icon icon-shape bg-primary text-white rounded-circle shadow'>
                          <i className='ni ni-app' />
                        </div>
                      </Col>
                    </Row>
                    <Progress
                      className='progress-xs mt-4 mb-0'
                      max={context.stripe.plan.materials}
                      value={context.materials.length}
                      color={progressBarColor()}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

CardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string
}

export default CardsHeader
