import React, { useEffect, useState } from 'react'
// Components
import { BasicStatCard } from './BasicStatCard'
// Firebase
import firebase from 'auth/Auth'
// Config
import { FILTERS } from 'config/filters'
// underscore
import _ from 'underscore'
// reactstrap components
import {
  Row,
  Col
} from 'reactstrap'

export const BasicStats = () => {
  const [colors, setColors] = useState([])
  const [categories, setCategories] = useState([])
  const [subcategories, setSubcategories] = useState([])
  const [formats, setFormats] = useState([])

  useEffect(() => {
    firebase.firestore()
      .collection('stats')
      .doc('usages')
      .onSnapshot(snapshot => {
        const colorsArray = Object.keys(snapshot.data().color).map(color => {
          return {
            name: color,
            value: snapshot.data().color[color]
          }
        })
        setColors(_.sortBy(colorsArray, 'value').reverse())

        const categoriesArray = Object.keys(snapshot.data().category).map(category => {
          return {
            name: category,
            value: snapshot.data().category[category]
          }
        })
        setCategories(_.sortBy(categoriesArray, 'value').reverse())

        const subcategoriesArray = Object.keys(snapshot.data().subcategory).map(subcategory => {
          return {
            name: subcategory,
            value: snapshot.data().subcategory[subcategory]
          }
        })
        setSubcategories(_.sortBy(subcategoriesArray, 'value').reverse())

        const formatsArray = Object.keys(snapshot.data().format).map(format => {
          return {
            name: format,
            value: snapshot.data().format[format]
          }
        })
        setFormats(_.sortBy(formatsArray, 'value').reverse())
      })
  }, [])

  return (
    <Row>
      <Col xl='3'>
        <BasicStatCard
          title='Colores más usados'
          stats={colors}
          statsColor
        />
      </Col>
      <Col xl='3'>
        <BasicStatCard
          title='Categorías más usadas'
          statsKey={FILTERS.category}
          stats={categories}
        />
      </Col>
      <Col xl='3'>
        <BasicStatCard
          title='Subcategorías más usadas'
          statsKey={FILTERS.category}
          statsSubKey={FILTERS.subcategory}
          stats={subcategories}
        />
      </Col>
      <Col xl='3'>
        <BasicStatCard
          title='Formatos más usados'
          statsKey={FILTERS.format}
          stats={formats}
        />
      </Col>
    </Row>
  )
}
