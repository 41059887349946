import React, { useState, useContext, useEffect } from 'react'
// Firebase
import firebase from 'auth/Auth'
// Context
import UserContext from 'context/UserContext'

export const PreviewThumb = props => {
  const [urlImage, setUrlImage] = useState(require('assets/img/brand/logo-circle.png'))
  const context = useContext(UserContext)

  useEffect(() => {
    if (props.materialId) {
      const path = `materials/${props.materialId}_200x200.jpg`
      firebase.storage().ref().child(path).getDownloadURL().then(url => {
        setUrlImage(url)
      })
    }
  }, [props.materialId, context.company.name])

  return (
    <div
      onClick={() => {
        window.open(props.fullImage)
      }}
      className='materialThumb cursor-pointer'
      alt='referencia'
      width='60'
      style={{
        width: 60,
        height: 60,
        backgroundSize: 'cover',
        backgroundImage: 'url(' + urlImage + ')'
      }}
    />
  )
}
