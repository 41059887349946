import Login from 'views/Login.js'
import Signup from 'views/Signup.js'
import ForgotPassword from 'views/ForgotPassword.js'
import Pricing from 'views/Pricing.js'

const routes = [
  {
    path: '/login',
    name: 'Login',
    miniName: 'L',
    component: Login,
    layout: '/auth',
    invisible: true
  },
  {
    path: '/signup',
    name: 'Signup',
    miniName: 'S',
    component: Signup,
    layout: '/auth',
    invisible: true
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    miniName: 'FP',
    component: ForgotPassword,
    layout: '/auth',
    invisible: true
  },
  {
    path: '/pricing',
    name: 'Pricing',
    miniName: 'P',
    component: Pricing,
    layout: '/auth',
    invisible: true
  }
]

export default routes
